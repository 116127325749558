import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { MyProfileService } from 'src/app/feature/user/my-profile/service/my-profile.service';

@Component({
  selector: 'app-waygate-notification-email',
  templateUrl: './waygate-notification-email.component.html',
  styleUrls: ['./waygate-notification-email.component.scss', './../waygate-manage-account.component.scss']
})
export class WaygateNotificationEmailComponent implements OnInit{
  loadingFlag: boolean;
  notificationEmailForm: FormGroup;
  data: any;
  orderAcknEmail: string = '';
  orderAcknName: string = '';
  orderAcknContact: string = '';
  shipNotiEmail: string = '';
  shipNotiName: string = '';
  shipNotiContact: string = '';
  invoiceEmail: string = '';
  invoiceName: string = '';
  invoiceContact: string = '';
  myProfile:any ={
    profileUpdated: "Your profile has been updated.",
    errormsg: "Something went wrong, please try again later."
  }

  constructor(private fb: FormBuilder,
    private profile: MyProfileService,
    private globalMessageService: GlobalMessageService,
    private translate: TranslationService,
  ){

  }

  ngOnInit(): void {
    this.notificationEmailForm = this.fb.group({
      serviceReminderSwitch: [true],
      orderAcknEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      orderAcknName: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(
        '^[\wÁ-ÿa-zA-Z0-9.+-/]+$'
      )]],
      orderAcknContact: ['', [Validators.required,Validators.pattern('^[0-9]{10}$')]],
      invoiceEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      invoiceName: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(
        '^[\wÁ-ÿa-zA-Z0-9 .+-/]+$'
      )]],
      invoiceContact: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]]
    })

    this.getNotificationDataFromAPI();
  }

  getNotificationDataFromAPI(){
    this.loadingFlag = true;
    this.profile.getNotificationData().subscribe(
      (success) => {
        this.data = success;
        this.invoiceEmail = this.data?.sendInvoiceEmail
          ? this.data?.sendInvoiceEmail
          : '';
        this.invoiceName = this.data?.invoiceContact
          ? this.data?.invoiceContact
          : '';
        this.invoiceContact = this.data?.invoicePhone
          ? this.data?.invoicePhone
          : '';
        this.orderAcknEmail = this.data?.sendSalesOrderEmail
          ? this.data?.sendSalesOrderEmail
          : '';
        this.orderAcknName = this.data?.soaContact
          ? this.data?.soaContact
          : '';
        this.orderAcknContact = this.data?.soaPhone
          ? this.data?.soaPhone
          : '';
        this.loadingFlag = false;
        this.setFormsControlValue();
      },
      (error) => {       
        this.loadingFlag = false;
        this.globalMessageService.add(
          this.myProfile.errormsg,
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      }
    );
  }

  setFormsControlValue(){
    this.notificationEmailForm.setValue(
      {serviceReminderSwitch: true, 
      orderAcknEmail: this.orderAcknEmail,
      orderAcknName:this.orderAcknName,
      orderAcknContact: this.orderAcknContact,
      invoiceEmail: this.invoiceEmail,
      invoiceName: this.invoiceName,
      invoiceContact: this.invoiceContact


      }
    )
  }

  discardChanges(){
    this.setFormsControlValue();
  }
  onSubmit(){
    this.loadingFlag = true;
    if(this.notificationEmailForm.valid){
      this.data = {
        sendInvoiceEmail: this.notificationEmailForm.value.invoiceEmail.toLowerCase(),
        sendSalesOrderEmail: this.notificationEmailForm.value.orderAcknEmail.toLowerCase(),        
        invoiceContact: this.notificationEmailForm.value.invoiceName,
        invoicePhone: this.notificationEmailForm.value.invoiceContact,
        soaContact: this.notificationEmailForm.value.orderAcknName,
        soaPhone: this.notificationEmailForm.value.orderAcknContact,
        ShippingContactName: this.shipNotiName,
        ShippingContactNumber: this.shipNotiContact,
        sendShippingNotificationEmail: this.shipNotiEmail.toLowerCase(),
      };
      
      this.profile.postNotificationData(this.data).subscribe((success) => {
        this.loadingFlag = false;
        if (success) {         
          this.globalMessageService.add(
            this.myProfile.profileUpdated,
            GlobalMessageType.MSG_TYPE_CONFIRMATION,
            5000
          );
          
        }
        
      },
      (error) => {          
        this.globalMessageService.add(
          this.myProfile.errormsg,
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        this.loadingFlag = false;
        window.scrollTo(0, 0);
      });

    }
    else{
      return null;
    }
  }

  get formControls(){
    return this.notificationEmailForm.controls
  }

}
