<ng-container *ngIf="product$ | async as product">
  <div class="container">
    <div class="left-items">
      <span id="title">Configure Product</span>
      <span id="pipe">|</span>
      <span id="prod-title">{{product.name}}</span>
    </div>
    <div class="right-items" *ngIf="configuration$ | async as configuration">
      <button id="reset-btn" (click)="resetData(configuration.configId)" class="reset-container"
       >
        <mat-icon>refresh</mat-icon>
        <div class="reset-font">Reset</div>
      </button>
      <button id="download-btn">
        
      <div class="download-csv-font">
      <span><img src="../../../../../assets/img/Download_csv.svg"></span>
      <div class="download-csv-label">Download CSV</div>  
      </div>
      </button>
    </div>
    
  </div>
</ng-container>
<br><br>
