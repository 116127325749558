import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GuestBuyCheckoutService } from 'src/app/feature/checkout/guest-checkout/services/guest-buy-checkout.service';
import {
  REGULAR_PATTERN,
  testRegex,
} from 'src/app/core/generic-validator/regular-expressions';
import { PlaceorderModel } from 'src/app/feature/checkout/buy-checkout/buy-checkout.model';

@Component({
  selector: 'app-waygate-compliance-questions',
  templateUrl: './waygate-compliance-questions.component.html',
  styleUrls: ['./waygate-compliance-questions.component.scss'],
})
export class WaygateComplianceQuestionsComponent {
  @Output() setComplianceData: EventEmitter<any> = new EventEmitter();
  @Output() isComplianceValid: EventEmitter<any> = new EventEmitter();

  radiobtnbuy: any;
  submitted: boolean = false;
  $subscription: Subscription;

  buyCheckoutModel: PlaceorderModel;
  exportAddress;

  constructor(
    private chkService: GuestBuyCheckoutService,
    public sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    this.buyCheckoutModel = new PlaceorderModel();
    this.$subscription = this.chkService
      .getValidation()
      .subscribe((notification) => {
        if (notification) {
          this.onSubmit();
        }
      });
  }

  handleChange(e, field) {
    if (field === 'radiobtnbuy') {
      this.radiobtnbuy = e.target.value;
      if (this.radiobtnbuy == 'false')
      {
        this.exportAddress = '';
        this.isComplianceValid.emit(true);
      } else if (this.radiobtnbuy == 'true' && (this.exportAddress != '' || this.exportAddress != undefined)) {
        this.isComplianceValid.emit(true);
      }
        
    }
    
  }
  onSubmit() {
    this.submitted = true;
    if (this.radiobtnbuy == undefined) {
      // window.scrollTo({ top: 2000, behavior: 'smooth' });
    }
    if (this.radiobtnbuy) {
      this.buyCheckoutModel.govtAgencyFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.nuclearOpportFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.planToExportFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.isBuyerFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.googleCaptcha = '';
      this.buyCheckoutModel.exportAddress = this.exportAddress
        ? testRegex(
            this.sanitizer.sanitize(
              SecurityContext.HTML,
              this.exportAddress.trim()
            ),
            REGULAR_PATTERN.alphaNumericWithSpecialCharater
          )
        : '';
      this.buyCheckoutModel.ndays = '';
      this.buyCheckoutModel.ndaysOfWeek = [];
      this.buyCheckoutModel.nthDayOfMonth = '';
      this.buyCheckoutModel.nweeks = '';
      this.buyCheckoutModel.requestedHdrDeliveryDate = '';
      this.buyCheckoutModel.replenishmentStartDate = '';
      this.buyCheckoutModel.replenishmentRecurrence = '';
      this.buyCheckoutModel.replenishmentOrder = false;
      this.buyCheckoutModel.securityCode = '';
      this.buyCheckoutModel.termsCheck = true;
      this.setComplianceData.emit(this.buyCheckoutModel);
    }
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
    this.chkService.setValidation(false);
  }
}
