import { Routes } from '@angular/router';
import { CmsPageGuard } from '@spartacus/storefront';
import { CartNotEmptyGuard } from 'src/app/feature/cart';
import { AccessDeniedComponent } from 'src/app/feature/user/access-denied/access-denied.component';
import { ProductDeniedComponent } from 'src/app/feature/user/product-denied/product-denied.component';
import { DashboardComponent } from 'src/app/feature/waygate-tech-landing/dashboard/dashboard.component';
import { QuickOrderComponent } from 'src/app/feature/waygate-tech-landing/quick-order/quick-order.component';
import { RegisterProgressPageComponent } from 'src/app/feature/waygate-tech-landing/registration/register-progress-page/register-progress-page.component';
import { RegistrationComponent } from 'src/app/feature/waygate-tech-landing/registration/registration.component';
import { WayagteMainStructureComponent } from 'src/app/feature/waygate-tech-landing/wayagte-main-structure/wayagte-main-structure.component';
import { WaygateCalibrationDataComponent } from 'src/app/feature/waygate-tech-landing/waygate-calibration-data/waygate-calibration-data.component';
import { WaygateCartDetailComponent } from 'src/app/feature/waygate-tech-landing/waygate-cart/waygate-cart-detail/waygate-cart-detail.component';
import { WaygateCartCheckoutComponent } from 'src/app/feature/waygate-tech-landing/waygate-checkout/waygate-cart-checkout/waygate-cart-checkout.component';
import { WaygateOrderConfirmationComponent } from 'src/app/feature/waygate-tech-landing/waygate-checkout/waygate-cart-checkout/waygate-order-confirmation/waygate-order-confirmation.component';
import { WaygateContactusComponent } from 'src/app/feature/waygate-tech-landing/waygate-contactus/waygate-contactus.component';
import { WaygateLandingComponent } from 'src/app/feature/waygate-tech-landing/waygate-landing/waygate-landing.component';
import { WaygateListingComponent } from 'src/app/feature/waygate-tech-landing/waygate-listing/waygate-listing.component';
import { WaygateManageAccountComponent } from 'src/app/feature/waygate-tech-landing/waygate-manage-account/waygate-manage-account.component';
import { WaygateMyFavouritesComponent } from 'src/app/feature/waygate-tech-landing/waygate-my-favourites/waygate-my-favourites.component';
import { WaygateOrderDetailsComponent } from 'src/app/feature/waygate-tech-landing/waygate-orders/waygate-order-details/waygate-order-details.component';
import { WaygateOrdersComponent } from 'src/app/feature/waygate-tech-landing/waygate-orders/waygate-orders.component';
import { WaygateProductDetailsComponent } from 'src/app/feature/waygate-tech-landing/waygate-product-details/waygate-product-details.component';
import { GuestUserOrderDetailsComponent } from 'src/app/feature/waygate-tech-landing/waygate-track-order/guest-user-order-details/guest-user-order-details.component';
import { WaygateTrackOrderComponent } from 'src/app/feature/waygate-tech-landing/waygate-track-order/waygate-track-order.component';
import { WaygateViewCartDetailsComponent } from 'src/app/feature/waygate-tech-landing/waygate-view-cart-details/waygate-view-cart-details.component';
import { WaygateViewCartComponent } from 'src/app/feature/waygate-tech-landing/waygate-view-cart/waygate-view-cart.component';
import {
  cartCheckoutCmsPageLabel,
  commonCmsPageLabel,
  quickOrderCmsPageLabel,
} from 'src/app/shared/enums/availableProductList.enum';
import { AnonymousUserGaurd } from 'src/app/shared/guards/anonymous-user.guard';
import { CSRAuthGuard } from 'src/app/shared/guards/csr-auth.guard';
import { CustomLoginGuard } from 'src/app/shared/guards/login.guard';
import { ProductLineGuard } from 'src/app/shared/guards/product-line.guard';
import { UserRoleGuard } from 'src/app/shared/guards/user-role.guard';
import { WaygateNavigateAwayGuard } from 'src/app/shared/guards/waygate-navigate-away.guard';

export const productLineRoutes: Routes = [];

for (let pageLabelKey in commonCmsPageLabel) {
  const productLineRoute = {
    path: pageLabelKey,
    component: WayagteMainStructureComponent,
    data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
    canActivate: [CmsPageGuard, ProductLineGuard],
    children: [
      {
        path: '',
        component: WaygateLandingComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'categories/:cCode/:cName',
        component: WaygateListingComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'search/:searchInput',
        component: WaygateListingComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'product/:pCode/:pName',
        component: WaygateProductDetailsComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'quick-order',
        component: QuickOrderComponent,
        data: { pageLabel: quickOrderCmsPageLabel[pageLabelKey] },
        canActivate: [CustomLoginGuard],
        canDeactivate: [WaygateNavigateAwayGuard],
      },
      {
        path: 'track-order',
        component: WaygateTrackOrderComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'track-order/details/:orderId',
        component: GuestUserOrderDetailsComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'my-orders',
        component: WaygateOrdersComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'my-orders/:orderId',
        component: WaygateOrderDetailsComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'cart',
        component: WaygateCartDetailComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'checkout',
        component: WaygateCartCheckoutComponent,
        data: { pageLabel: cartCheckoutCmsPageLabel[pageLabelKey] },
        canActivate: [
          CmsPageGuard,
          UserRoleGuard,
          CustomLoginGuard,
          CartNotEmptyGuard,
        ],
      },
      {
        path: 'order-summary/:id',
        component: WaygateOrderConfirmationComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'manage-account',
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
        component: WaygateManageAccountComponent,
      },
      {
        path: 'saved-carts',
        component: WaygateViewCartComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'saved-carts/:cartId',
        component: WaygateViewCartDetailsComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'my-favorites',
        component: WaygateMyFavouritesComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
      },
      {
        path: 'calibration-data',
        component: WaygateCalibrationDataComponent,
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
        canActivate: [CmsPageGuard],
      },
      {
        path: 'register',
        component: RegistrationComponent,
        // data: {pageLabel: '/waygateHomepage'},
        canActivate: [AnonymousUserGaurd],
      },
      {
        path: 'register/progress-page',
        component: RegisterProgressPageComponent,
        canActivate: [AnonymousUserGaurd],
      },
      {
        path: 'contactus',
        component: WaygateContactusComponent,
        // canActivate: [AnonymousUserGaurd],
        data: { pageLabel: commonCmsPageLabel[pageLabelKey] },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { pageLabel: '/waygateHomepage' },
        canActivate: [
          CmsPageGuard,
          UserRoleGuard,
          CustomLoginGuard,
          CSRAuthGuard,
        ],
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: { pageLabel: '/waygateHomepage' },
        canActivate: [CmsPageGuard],
      },
    ],
  };
  productLineRoutes.push(productLineRoute);
}
