import { Routes } from '@angular/router';
import { CmsPageGuard } from '@spartacus/storefront';
import { CartDetailsComponent } from 'src/app/feature/cart';
import { MyFavoritesComponent } from 'src/app/feature/my-favorites/my-favorites.component';
import { OrderTrackingDetailComponent } from 'src/app/feature/order-tracking/order-tracking-detail/order-tracking-detail.component';
import { OrderTrackingListComponent } from 'src/app/feature/order-tracking/order-tracking-list/order-tracking-list.component';
import { RmaListingComponent } from 'src/app/feature/rma/rma-tracking/rma-listing/rma-listing.component';
import { SampleComponent } from 'src/app/feature/sample/sample.component';
import { LoginFormComponent } from 'src/app/feature/user/login-form/login-form.component';

import { RmaFormContainerComponent } from 'src/app/feature/rma/rma-form/rma-form-container/rma-form-container.component';
import { HazardInfoContainerComponent } from 'src/app/feature/rma/hazard-info/hazard-info-container/hazard-info-container.component';
import { RmaCheckoutdetailsComponent } from 'src/app/feature/rma/rma-checkout/rma-checkoutdetails/rma-checkoutdetails.component';
import { ViewCartComponent } from 'src/app/feature/saved-cart/view-cart/view-cart.component';
import { OrderSummaryComponent } from 'src/app/feature/checkout/buy-checkout/order-summary/order-summary.component';
import { RmaCartContainerComponent } from 'src/app/feature/rma/rma-cart/rma-cart-container/rma-cart-container.component';
import { ViewCartDetailComponent } from 'src/app/feature/saved-cart/view-cart-detail/view-cart-detail.component';
import { RmaConfirmationComponent } from 'src/app/feature/rma/rma-checkout/rma-confirmation/rma-confirmation.component';
import { RmaStatusComponent } from 'src/app/feature/rma/rma-status/rma-status/rma-status.component';
import { ContactUsComponent } from 'src/app/feature/contact-us/contact-us.component';
import { LinkComponent } from 'src/app/feature/links/link/link.component';
import { FeedbackComponent } from 'src/app/feature/feedback/feedback/feedback.component';
import { MyProfileComponent } from 'src/app/feature/user/my-profile/my-profile/my-profile.component';
import { GuestQuoteCartComponent } from 'src/app/feature/quote/guest-quote-cart/guest-quote-cart.component';
import { GuestQuoteCheckoutComponent } from 'src/app/feature/quote/guest-quote-checkout/guest-quote-checkout.component';
import { RmaTrackingDetailComponent } from 'src/app/feature/rma/rma-tracking/rma-detail/rma-tracking-detail/rma-tracking-detail.component';
import { LandingPagesComponent } from 'src/app/feature/landing/landing-pages.component';
import { CheckoutComponent } from 'src/app/feature/checkout/checkout/checkout.component';
import { PartWithErrorComponent } from 'src/app/feature/landing/loggedin-home/part-with-error/part-with-error.component';

import { AddEquipmentToWatchlistComponent } from 'src/app/feature/site-equipments/add-equipment-to-watchlist/add-equipment-to-watchlist.component';
import { EquipmentDetailsComponent } from 'src/app/feature/site-equipments/equipment-details/equipment-details.component';
import { UpdateEquipmentDetailsComponent } from 'src/app/feature/site-equipments/update-equipment-details/update-equipment-details.component';
import { CalibrationDataComponent } from 'src/app/feature/calibration-data/calibration-data.component';
import { MyEquipmentsComponent } from 'src/app/feature/site-equipments/my-equipments/my-equipments.component';
import { DSAuthGuard } from '../auth/ds-auth.guard';
import { SsoRedirectComponent } from 'src/app/feature/user/sso-redirect/sso-redirect.component';
import { AccessDeniedComponent } from 'src/app/feature/user/access-denied/access-denied.component';
import { DetailedNotificationPageComponent } from 'src/app/feature/notifications/detailed-notification-page/detailed-notification-page.component';
import { UserRoleGuard } from 'src/app/shared/guards/user-role.guard';
import { CustomLoginGuard } from 'src/app/shared/guards/login.guard';
import { ReactivateComponent } from 'src/app/feature/user/reactivate/reactivate.component';
import { SearchPrivateFolderComponent } from 'src/app/feature/site-equipments/search-private-folder/search-private-folder.component';
import { TrainingDocsComponent } from 'src/app/feature/training-docs/training-docs.component';
import { CartNotEmptyGuard } from 'src/app/feature/cart/cart-not-empty.guard';
import { ProductDeniedComponent } from 'src/app/feature/user/product-denied/product-denied.component';
import { ChooseBrandComponent } from 'src/app/feature/choose-brand/choose-brand.component';
import { CSRAuthGuard } from 'src/app/shared/guards/csr-auth.guard';
/**
 * Demonstrates static routes and CMS based routes.
 */
export const staticRoutes: Routes = [
  {
    path: 'sample-page',
    component: SampleComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'rma-form',
    component: RmaFormContainerComponent,
    data: { pageLabel: '/rma-form' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'rma-form/hazard-info',
    component: HazardInfoContainerComponent,
    data: { pageLabel: '/rma-hazard' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'my-favorites',
    component: MyFavoritesComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'my-orders',
    component: OrderTrackingListComponent,
    data: { pageLabel: 'order-history' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'rma-tracking',
    component: RmaListingComponent,
    data: { pageLabel: 'rma-status' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'order-details/:id',
    component: OrderTrackingDetailComponent,
    data: { pageLabel: 'order-detail' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'rma-details/:id',
    component: RmaTrackingDetailComponent,
    data: { pageLabel: 'rma-details' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'cart',
    component: CartDetailsComponent,
    data: { pageLabel: '/cart' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'spa_redirect',
    component: SsoRedirectComponent,
    data: { pageLabel: '/homepage'  },
    canActivate: [DSAuthGuard, CmsPageGuard ],
  },
  {
    path: 'dshome/account/reactivate/:site/:id',
    component: ReactivateComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'dshome/account/reactivate/:site/:id/:name',
    component: ReactivateComponent,
    canActivate: [CmsPageGuard],
  },
  /* {
    path: 'redirect_uri',
    component: SsoRedirectComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard, DSAuthGuard],
  }, */
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'page-not-found',
    component: ProductDeniedComponent,
    data: { pageLabel: '/not-found' },
    canActivate: [CmsPageGuard],
  },

  {
    path: 'checkout',
    component: CheckoutComponent,
    data: { pageLabel: '/cartCheckout' },
    canActivate: [CmsPageGuard, UserRoleGuard, CartNotEmptyGuard],
  },
  {
    path: 'order-summary/:id',
    component: OrderSummaryComponent,
    data: { pageLabel: '/order-confirmation' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'rma/cart',
    component: RmaCartContainerComponent,
    data: { pageLabel: '/rma-cart' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'rma/checkout',
    component: RmaCheckoutdetailsComponent,
    data: { pageLabel: '/rmaCheckout' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'saved-carts',
    component: ViewCartComponent,
    data: { pageLabel: '/saved-carts' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  // {
  //   path: 'saved-carts',
  //   component: WaygateViewCartComponent,
  //   data: { pageLabel: '/saved-carts' },
  //   canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  // },
  {
    path: 'saved-cart/details',
    component: ViewCartDetailComponent,
    data: { pageLabel: '/saved-carts-details' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'rma-confirmation/:id',
    component: RmaConfirmationComponent,
    data: { pageLabel: '/rma-confirmation' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'quick-status',
    component: RmaStatusComponent,
    data: { pageLabel: '/rma-status' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'quick-status/order/:id',
    component: OrderTrackingDetailComponent,
    data: { pageLabel: 'order-detail' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'quick-status/rma/:id',
    component: RmaTrackingDetailComponent,
    data: { pageLabel: 'rma-details' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'order-confirmation/:id',
    component: OrderSummaryComponent,
    data: { pageLabel: '/order-confirmation' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'contactus',
    component: ContactUsComponent,
    data: { pageLabel: '/contactus' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'list-of-portals',
    component: LinkComponent,
    data: { pageLabel: '/listOfPortals' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    data: { pageLabel: '/feedback' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    data: { pageLabel: '/my-profile' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'quote/cart',
    component: GuestQuoteCartComponent,
    data: { pageLabel: '/quote-cart' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'quote/checkout',
    component: GuestQuoteCheckoutComponent,
    data: { pageLabel: '/quote-checkout' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'homepage',
    component: LandingPagesComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'home',
    component: LandingPagesComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: '',
    component: LandingPagesComponent,
    data: { pageLabel: '/homepage' },
    canActivate: [CmsPageGuard, UserRoleGuard],
  },
  {
    path: 'bulk-order',
    component: PartWithErrorComponent,
    data: { pageLabel: '/bulk-order' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'add-equipment-to-watchlist',
    component: AddEquipmentToWatchlistComponent,
    data: { pageLabel: '/add-equipment' },
    canActivate: [CmsPageGuard, CustomLoginGuard],
  },
  /*{
    path: 'add-equipment',
    component: AddEquipmentComponent,
    data: { pageLabel: '/add-equipment' },
    canActivate: [CmsPageGuard],
  },*/
  {
    path: 'equipment-details',
    component: EquipmentDetailsComponent,
    data: { pageLabel: '/equipment-details' },
    canActivate: [CmsPageGuard, CustomLoginGuard],
  },
  {
    path: 'update-equipment-details',
    component: UpdateEquipmentDetailsComponent,
    data: { pageLabel: '/equipment-details' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'calibration-data',
    component: CalibrationDataComponent,
    data: { pageLabel: '/equipment-details' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'my-equipments',
    component: MyEquipmentsComponent,
    data: { pageLabel: '/calibration-data' },
    canActivate: [CmsPageGuard, CustomLoginGuard],
  },
  {
    path: 'detailed-notification-page',
    component: DetailedNotificationPageComponent,
    data: { pageLabel: '/calibration-data' },
    canActivate: [CmsPageGuard, CustomLoginGuard],
  },
  {
    path: 'search-private-folder',
    component: SearchPrivateFolderComponent,
    data: { pageLabel: '/private-folder-search' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  },
  {
    path: 'training-docs',
    component: TrainingDocsComponent,
    data: { pageLabel: '/training-document' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'choose-brand',
    component: ChooseBrandComponent,
    data: { pageLabel: '/waygateHomepage' },
    canActivate: [CmsPageGuard, UserRoleGuard, CustomLoginGuard],
  }
];
