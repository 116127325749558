<ng-container
  *ngIf="
    currentReturnAction == returnActions.RETURN ||
      currentBuyAction == buyActions.BUY
  "
>
  <ng-container *ngIf="userLoggedIn$ | async">
    <form [formGroup]="addToCartForm">
      <div class="waygate-actions-top">
        <div class="actions-top-item">
          <div class="actions-top-label">{{'buyCart.quantity' | cxTranslate}}</div>
          <app-waygate-counter
            [max]="9999"
            [control]="addToCartForm.get('quantity')"
            (quantityAdded)="quantityAdded($event)"
            (quantityIncrement)="increaseQuantity($event)"
            (quantityDecrement)="decreaseQuantity($event)"
          ></app-waygate-counter>
        </div>
        <div class="actions-top-item"  *ngIf="currentBuyAction == buyActions.BUY">
          <div class="actions-top-label">{{'buyCart.estimatedShipDate' | cxTranslate}}</div>
          <!-- <div class="actions-top-label" [innerHTML]="product?.estShipData ? (product?.estShipData[product?.estShipData?.length - 1]?.shipDate | date: 'dd MMM yyyy') : ''"></div> -->
          <vs-date-picker
            id="requestShipDate"
            class="date-picker-waygate-product-details"
            [filterWeekend]="true"
            [defaultDate]="product?.estShipData ? product?.estShipData[product?.estShipData?.length - 1]?.shipDate : ''"
            [min]="minDate"
          >
          </vs-date-picker>
        </div>
        <div class="actions-top-item">
          <bh-button
            class="update-bh-button"
            medium="true"
            [type]="'secondary'"
            label="{{ 'Check Availability' }}"
            (click)="updateAvailablity()"
            [attr.disabled]="disableCheckAvailablity"
          ></bh-button>
        </div>
      </div>
    </form>
    <div class="waygate-availability" *ngIf="disableCheckAvailablity">
      <div class="title-heading">{{'buyCart.availability' | cxTranslate}}</div>
      <ng-container>
        <ng-container
          *ngIf="checkStockAvailablity(product)"
        >
          <div class="success-available">{{'buyCart.inStock' | cxTranslate}}</div>
        </ng-container>
        <ng-container
          *ngIf="!checkStockAvailablity(product)"
        >
          <ng-container *ngIf="getInt(product) < 1">
            <div class="danger-out-of-stock">
              {{'buyCart.noStockAvailable' | cxTranslate}}
            </div>
          </ng-container>
          <ng-container *ngIf="getInt(product) > 0">
            <div class="">
              {{ getInt(product) }} {{'buyCart.quantityIsAvailableOn' | cxTranslate}}
              {{ getShipDate(product?.estShipData[0]) }}
            </div>
          </ng-container>
          <div *ngIf="getInt(product) > 0">
            {{'buyCart.leadTimeForReaminingQtyIs' | cxTranslate}}
            <strong>{{ product?.leadTime }} {{'buyCart.weeks' | cxTranslate}}</strong>.
          </div>
          <div *ngIf="getInt(product) < 1 && product?.leadTime !== undefined">
            {{'buyCart.leadTimeIs' | cxTranslate}}
            <strong>{{ product?.leadTime }} {{'buyCart.weeks' | cxTranslate}}</strong>.
          </div>
          <div *ngIf="getInt(product) < 1 && product?.leadTime === undefined">
            {{'buyCart.leadTimeForRemainingQtyIsNotAvailable' | cxTranslate}} <a [routerLink]="contactUsUrl"> {{'buyCart.contact' | cxTranslate}}</a> {{'buyCart.customerCare' | cxTranslate}}
          </div>
          <div></div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<div class="waygate-pdp-action-button">
  <ng-container *ngIf="userLoggedIn$ | async; else anonymous">
    <ng-container
      *ngIf="
        currentReturnAction == returnActions.RETURN ||
      currentBuyAction == buyActions.BUY;
        else noWaygate
      "
    >
      <button
        class="waygate-add-to-cart"
        (click)="addToCart()"
        *ngIf="currentBuyAction == buyActions.BUY"
      >
        <div class="button-label">{{'buyCart.addToCart' | cxTranslate}}</div>
        <span class="material-icons"> add_shopping_cart </span>
      </button>
      <button
        class="waygate-return-product"
        (click)="returnProduct()"
        *ngIf="currentReturnAction == returnActions.RETURN"
      >
        <div class="button-label">{{'buyCart.return' | cxTranslate}}</div>
        <span class="material-icons"> local_shipping </span>
      </button>
    </ng-container>
  </ng-container>
  <ng-template #anonymous>
    <button
      class="waygate-add-to-cart"
      (click)="requestQuote()"
      *ngIf="currentGuestAction == guestActions.QUOTE; else noQuote"
    >
      <div class="button-label">{{'buyCart.requestforQuote' | cxTranslate}}</div>
      <span class="material-icons"> list_alt </span>
    </button>
  </ng-template>
</div>
<ng-template #noWaygate>
  <div class="product-not-available" *ngIf="product?.code">
    {{'buyCart.productIsNotAvailable' | cxTranslate}}
    <br />
    {{'buyCart.please' | cxTranslate}} <a [routerLink]="contactUsUrl"> {{'buyCart.contactUs' | cxTranslate}}</a>.
  </div>
</ng-template>
<ng-template #noQuote>
  <div class="product-not-available login-text" *ngIf="product?.code">
    {{'buyCart.PleaseLogIn' | cxTranslate}}
  </div>
</ng-template>
