import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { AuthService, TranslationService } from '@spartacus/core';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';

import { Router } from '@angular/router';
import { RmaTrackingService } from 'src/app/feature/rma/rma-tracking/rma-tracking.service';
import { OrderTrackingService } from 'src/app/feature/order-tracking/order-tracking.service';
import { LaunchDialogService } from '@spartacus/storefront';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
import { switchMap, take } from 'rxjs/operators';

import {
  REGULAR_PATTERN,
  testRegex,
} from 'src/app/core/generic-validator/regular-expressions';
import { DomSanitizer } from '@angular/platform-browser';
import { LandingPagesService } from '../../landing/landing-pages.service';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';
import { environment } from 'src/environments/environment';

import { AllProductLine } from 'src/app/shared/enums/availableProductList.enum';

@Component({
  selector: 'app-waygate-track-order',
  templateUrl: './waygate-track-order.component.html',
  styleUrls: ['./waygate-track-order.component.scss'],
})
export class WaygateTrackOrderComponent implements OnInit {
  breadcrumbs: any[] = [];
  purchaseNumber = '';
  checkStatusNumber = '';
  stausName: string;
  statusDetails: any;
  isUserLoggedIn = false;
  noDataFound = false;
  showStatus = false;
  currentUid: string = '';
  googleCaptcha: string = '';
  showSpinner: boolean = false;
  orderStatus: boolean = false;
  rmaStatus: boolean = false;
  isLoggedIn: boolean = false;
  showWaygate: boolean;
  error = {
    purchaseNumber: '',
    currentUid: '',
  };
  productLine: string;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private landingPagesService: LandingPagesService,
    private customerAccountService: CustomerAccountService,
    private launchDialogService: LaunchDialogService,
    public rmaTrackingService: RmaTrackingService,
    public orderTrackingService: OrderTrackingService,
    private gtmService: GoogleTagManagerService,
    private translate: TranslationService
  ) {}

  ngOnInit(): void {
    this.customerAccountService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      if (
        productLine == AllProductLine.waygate ||
        productLine == AllProductLine.bently || 
        productLine == AllProductLine.panametrics ||
        productLine == AllProductLine.druck ||
        productLine == AllProductLine.reuterStokes
      ) {
        this.showWaygate = true;
      } else {
        this.showWaygate = false;
      }
    });
    this.getCurrentSalesArea();
    this.translate
      .translate('track-order.trackOrderBreadcrumb')
      .subscribe((res: string) => {
        this.breadcrumbs = [
          { name: res, url: `/${this.productLine}/track-order` },
        ];
      });
    this.authService.isUserLoggedIn().subscribe((success) => {
      if (success) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  getCurrentSalesArea() {
    this.customerAccountService.getCurrentCustomerAccount().subscribe((res) => {
      if (res) {
        this.currentUid = res?.uid ?? '';
      }
    });
  }
  orderRedirect(code, orderDetails) {
    this.orderTrackingService.emitOrderDetail.next(orderDetails);
    const redirectUrl = this.isLoggedIn
      ? `/${this.productLine}/my-orders`
      : `/${this.productLine}/track-order/details`;

    this.router.navigate([redirectUrl, code]);
  }

  onChange(event, order) {
    this.noDataFound = false;
    this.rmaStatus = false;
    this.orderStatus = false;
    this.showStatus = false;
    if (order === 'purchaseNumber') {
      this.purchaseNumber = testRegex(
        this.sanitizer.sanitize(SecurityContext.HTML, event.target.value),
        REGULAR_PATTERN.alphaNumeric
      );
      this.purchaseNumber = this.purchaseNumber.trim();
      this.error.purchaseNumber = '';
      this.stausName = order;
      this.checkStatusNumber = testRegex(
        this.sanitizer.sanitize(SecurityContext.HTML, event.target.value),
        REGULAR_PATTERN.alphaNumeric
      );
    } else {
      this.currentUid = event.target.value;
      if (
        event.target.value.match(/^[0-9]+$/) === null &&
        event.target.value !== ''
      ) {
        this.error.currentUid =
          'This is a number. Do not include spaces or other characters';
      } else {
        this.error.currentUid = '';
      }
    }
  }

  reset() {
    this.purchaseNumber = '';
    this.error.purchaseNumber = '';
    this.error.currentUid = '';
    this.checkStatusNumber = '';
    this.noDataFound = false;
    this.showStatus = false;
    this.rmaStatus = false;
    this.orderStatus = false;
    if (!this.isLoggedIn) {
      this.currentUid = '';
    }
  }

  whereDofind() {
    const guestTrackModal = this.launchDialogService.openDialog(
      DS_DIALOG.GUEST_TRACK_DIALOG,
      undefined,
      undefined,
      {}
    );
    if (guestTrackModal) {
      guestTrackModal.pipe(take(1)).subscribe((value) => {});
    }
  }

  rmaRedirect(rmaNumber, element) {
    this.rmaTrackingService.emitRmaDetail.next(element);
    const redirectUrl = this.isLoggedIn ? '/rma-details' : '/quick-status/rma';

    if (!this.isLoggedIn) {
      this.orderTrackingService.emitGuestOrderDetail.next('true');
    }
    this.router.navigate([redirectUrl, rmaNumber]);
  }

  findOrderStatusByPurchaseNumber() {
    this.showSpinner = true;
    this.noDataFound = false;
    this.landingPagesService
      .orderStatus(this.checkStatusNumber, this.currentUid, 'purchaseNumber')
      .subscribe(
        (resp: any) => {
          this.statusDetails = resp.orderData;
          if (this.statusDetails === undefined || this.statusDetails === null) {
            this.findOrderStatusByOrderNumber();
          } else {
            if (resp.orderData.length == 1) {
              this.orderRedirect(resp.orderData[0]?.code, resp.orderData[0]);
            } else {
              this.updateFlagsToShowData();
            }
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }

  findOrderStatusByOrderNumber() {
    this.landingPagesService
      .orderStatus(this.checkStatusNumber, this.currentUid, 'orderNumber')
      .subscribe(
        (resp: any) => {
          this.statusDetails = resp.orderData;
          if (this.statusDetails === undefined || this.statusDetails === null) {
            this.findRmaOrderStatus();
          } else {
            if (resp.orderData.length == 1) {
              this.orderRedirect(resp.orderData[0]?.code, resp.orderData[0]);
            } else {
              this.updateFlagsToShowData();
            }
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }

  findRmaOrderStatus() {
    this.showStatus = false;
    this.landingPagesService
      .rmaOrderStatus(this.checkStatusNumber, this.currentUid, 'purchaseNumber')
      .subscribe((data: any) => {
        this.statusDetails = data.rmaHeaderStatusDetails;
        if (this.statusDetails.length > 0) {
          if (this.statusDetails.length == 1) {
            this.rmaRedirect(
              this.statusDetails[0]?.rmaNumber,
              this.statusDetails[0]
            );
          } else {
            this.rmaStatus = true;
            this.showStatus = true;
            this.showSpinner = false;
          }
        } else {
          this.findOrderStatusByRmaPoNumber();
        }
      });
  }

  findOrderStatusByRmaPoNumber() {
    this.landingPagesService
      .rmaOrderStatus(this.checkStatusNumber, this.currentUid, 'orderNumber')
      .subscribe(
        (data: any) => {
          this.statusDetails = data.rmaHeaderStatusDetails;
          if (this.statusDetails.length > 0) {
            if (this.statusDetails.length == 1) {
              this.rmaRedirect(
                this.statusDetails[0]?.rmaNumber,
                this.statusDetails[0]
              );
            } else {
              this.rmaStatus = true;
              this.showStatus = true;
              this.showSpinner = false;
            }
          } else {
            this.showStatus = false;
            this.showSpinner = false;
            this.noDataFound = true;
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }

  updateFlagsToShowData() {
    this.orderStatus = true;
    this.showStatus = true;
    this.showSpinner = false;
  }

  trackOrderStatus() {
    if (!this.currentUid || !this.checkStatusNumber) {
      if (!this.currentUid) {
        this.error.currentUid = 'Please enter customer number';
      }
      if (!this.checkStatusNumber) {
        this.error.purchaseNumber = 'Please enter PO or Order or RMA number';
      }
    } else {
      if (this.isLoggedIn) {
        this.findOrderStatusByPurchaseNumber();
      } else {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(environment.siteKey, { action: '' })
            .then((token) => {
              this.googleCaptcha = token;
              this.getOrderStatusForGuestUserByPoNumber();
            });
        });
        this.pushEventForGtm('PO Number');
      }
    }
  }

  pushEventForGtm(queryType: string) {
    this.gtmService.sendEvent({
      event: 'guestQuickOrderTracker',
      poOrderNumber: this.checkStatusNumber,
      queryType: queryType,
    });
  }

  getOrderStatusForGuestUserByPoNumber() {
    this.showSpinner = true;
    this.landingPagesService
      .quickOrderStatus({
        customerNumber: this.currentUid,
        poNumber: this.checkStatusNumber,
        googleCaptcha: this.googleCaptcha,
      })
      .subscribe(
        (resp: any) => {
          this.statusDetails = resp.orderData;

          if (this.statusDetails === undefined || this.statusDetails === null) {
            grecaptcha.ready(() => {
              grecaptcha
                .execute(environment.siteKey, { action: '' })
                .then((token) => {
                  this.googleCaptcha = token;
                  this.getOrderStatusForGuestUserByRmaNumber();
                });
            });
            this.pushEventForGtm('Order/RMA Number');
          } else {
            if (resp.orderData.length == 1) {
              this.orderRedirect(resp.orderData[0]?.code, resp.orderData[0]);
            } else {
              this.orderStatus = true;
              this.showStatus = true;
              this.showSpinner = false;
            }
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }

  getOrderStatusForGuestUserByRmaNumber() {
    this.showSpinner = true;
    this.landingPagesService
      .quickOrderStatus({
        customerNumber: this.currentUid,
        rmaNumber: this.checkStatusNumber,
        googleCaptcha: this.googleCaptcha,
      })
      .subscribe(
        (resp: any) => {
          this.statusDetails = resp.orderData;

          if (this.statusDetails === undefined || this.statusDetails === null) {
            grecaptcha.ready(() => {
              grecaptcha
                .execute(environment.siteKey, { action: '' })
                .then((token) => {
                  this.googleCaptcha = token;
                  this.getRmaStatusForGuestUserByPoNumber();
                });
            });
          } else {
            if (resp.orderData.length == 1) {
              this.orderRedirect(resp.orderData[0]?.code, resp.orderData[0]);
            } else {
              this.orderStatus = true;
              this.showStatus = true;
              this.showSpinner = false;
            }
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }

  getRmaStatusForGuestUserByPoNumber() {
    this.showSpinner = true;
    this.landingPagesService
      .rmaStatusDetails({
        customerNumber: this.currentUid,
        poNumber: this.checkStatusNumber,
        googleCaptcha: this.googleCaptcha,
      })
      .subscribe(
        (data: any) => {
          if ((data.errorMsg && data.errorMsg !== undefined) || data === null) {
            grecaptcha.ready(() => {
              grecaptcha
                .execute(environment.siteKey, { action: '' })
                .then((token) => {
                  this.googleCaptcha = token;
                  this.getRmaStatusForGuestUserByRmaNumber();
                });
            });
          } else {
            this.rmaRedirect(data?.rmaNumber, data);
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }

  getRmaStatusForGuestUserByRmaNumber() {
    this.showSpinner = true;
    this.landingPagesService
      .rmaStatusDetails({
        customerNumber: this.currentUid,
        rmaNumber: this.checkStatusNumber,
        googleCaptcha: this.googleCaptcha,
      })
      .subscribe(
        (data: any) => {
          if ((data.errorMsg && data.errorMsg !== undefined) || data === null) {
            this.noDataFound = true;
            this.showStatus = false;
          } else {
            this.rmaRedirect(data?.rmaNumber, data);
          }
          this.showSpinner = false;
        },
        (error) => {
          this.showSpinner = false;
        }
      );
  }
}
