
import { ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthService, GlobalMessageService, GlobalMessageType, Product, ProductScope, ProductService, RoutingService } from '@spartacus/core';
import { Order, OrderHistoryFacade } from '@spartacus/order/root';
import {
  CommonConfigurator,
  CommonConfiguratorUtilsService,
  ConfiguratorModelUtils,
  ConfiguratorRouter,
  ConfiguratorRouterExtractorService,
} from '@spartacus/product-configurator/common';
import { Configurator, ConfiguratorCartService, ConfiguratorCommonsService, ConfiguratorGroupsService, ConfiguratorQuantityService, ConfiguratorStorefrontUtilsService } from '@spartacus/product-configurator/rulebased';
import { CurrentProductService, ICON_TYPE, IntersectionOptions, IntersectionService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';

import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { BuyActions } from 'src/app/core/product-catalog/model/product-catelog.model';
import { ProductCatelogService } from 'src/app/core/product-catalog/services/product-catelog.service';
import { MyFavoritesService } from 'src/app/feature/my-favorites/my-favorites.service';
import { GtmEvents, ItemListTypeEnum, StoreTypeEnum } from 'src/app/shared/enums/gtm.enum';
import { GTMDataLayer } from 'src/app/shared/models/googleTagManager.model';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';




@Component({
  selector: 'cx-configurator-price-summary',
  templateUrl: './configurator-price-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls:['./configurator-price-summary.component.scss']
})
export class ConfiguratorPriceSummaryComponent {
  

  isChecked=false;
  productLine: string;
  totalPriceCheck:boolean=false;
  ngOnInit(){
    this.customerAccService.getProductLine().subscribe((productLine) => {
      
      this.productLine = productLine;

    });

  }
  onImageError(event:Event):void{
    const target=event.target as HTMLImageElement;
    target.style.display='none';
  }
  product$: Observable<
    Product
  > = this.configRouterExtractorService.extractRouterData().pipe(
    switchMap((routerData) =>
      this.configuratorCommonsService.getConfiguration(routerData.owner)
    ),
    map((configuration) => {
      switch (configuration.owner.type) {
        case CommonConfigurator.OwnerType.PRODUCT:
        case CommonConfigurator.OwnerType.CART_ENTRY:
          return configuration.productCode;
        case CommonConfigurator.OwnerType.ORDER_ENTRY:
          return configuration.overview.productCode;
      }
    }),
    switchMap((productCode) => this.productService.get(productCode))
  );
  configuration$: Observable<
    Configurator.Configuration
  > = this.configRouterExtractorService.extractRouterData().pipe(
    switchMap((routerData) => {
      return this.configuratorCommonsService.getConfiguration(routerData.owner);
    })
  );
  showMore = false;
  iconTypes = ICON_TYPE;

  constructor(
    protected configuratorCommonsService: ConfiguratorCommonsService,
    protected configRouterExtractorService: ConfiguratorRouterExtractorService,
    
    private productService: ProductService,
    private auth: AuthService,
    private userAccountFacade: UserAccountFacade,
    private route: ActivatedRoute,
    private productCatelogService: ProductCatelogService,
    private currentProductService: CurrentProductService,
    private myFavouritesService: MyFavoritesService,
    private globalMessageService: GlobalMessageService,
    private cd: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    protected routingService: RoutingService,
    protected configuratorCartService: ConfiguratorCartService,
    protected configuratorGroupsService: ConfiguratorGroupsService,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected commonConfiguratorUtilsService: CommonConfiguratorUtilsService,
    protected configUtils: ConfiguratorStorefrontUtilsService,
    protected intersectionService: IntersectionService,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    protected configuratorQuantityService: ConfiguratorQuantityService,
    private customerAccService: CustomerAccountService,
    protected router:Router
    
  ) {}

  getProductImageURL(product: Product): string {
    return product.images?.PRIMARY?.['thumbnail']?.url;
  }

  getProductImageAlt(product: Product): string {
    return product.images?.PRIMARY?.['thumbnail']?.altText;
  }

  updatePrice(){
    this.totalPriceCheck=true;
  }
  protected subscription = new Subscription();
  quantityControl = new UntypedFormControl(1);
  iconType = ICON_TYPE;

  container$: Observable<{
    routerData: ConfiguratorRouter.Data;
    configuration: Configurator.Configuration;
    hasPendingChanges: boolean;
  }> = this.configRouterExtractorService.extractRouterData().pipe(
    switchMap((routerData) =>
      this.configuratorCommonsService
        .getConfiguration(routerData.owner)
        .pipe(map((configuration) => ({ routerData, configuration })))
        .pipe(
          switchMap((cont) =>
            this.configuratorCommonsService
              .hasPendingChanges(cont.configuration.owner)
              .pipe(
                map((hasPendingChanges) => ({
                  routerData: cont.routerData,
                  configuration: cont.configuration,
                  hasPendingChanges,
                }))
              )
          )
        )
    )
  );
  

  protected navigateToCart(): void {
    this.router.navigate([this.productLine,'cart']);
    /* this.routingService.go('cart'); */
  }

  protected navigateToOverview(
    configuratorType: string,
    owner: CommonConfigurator.Owner
  ): void {
    this.routingService.go({
      cxRoute: 'configureOverview' + configuratorType,
      params: { ownerType: 'cartEntry', entityKey: owner.id },
    });
  }

  protected displayConfirmationMessage(key: string): void {
    this.globalMessageService.add(
      { key: key },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
  }

  /**
   * Performs the navigation to the corresponding location (cart or overview pages).
   *
   * @param {string} configuratorType - Configurator type
   * @param {CommonConfigurator.Owner} owner - Owner
   * @param {boolean} isAdd - Is add to cart
   * @param {boolean} isOverview - Is overview page
   * @param {boolean} showMessage - Show message
   */
  performNavigation(
    configuratorType: string,
    owner: CommonConfigurator.Owner,
    isAdd: boolean,
    isOverview: boolean,
    showMessage: boolean
  ): void {
    const messageKey = isAdd
      ? 'configurator.addToCart.confirmation'
      : 'configurator.addToCart.confirmationUpdate';
/*     if (isOverview) {
      this.navigateToCart();
    } else {
      this.navigateToOverview(configuratorType, owner);
    } */
    this.navigateToCart();
    if (showMessage) {
      this.displayConfirmationMessage(messageKey);
    }
  }

  /**
   * Decides on the resource key for the button. Depending on the business process (owner of the configuration) and the
   * need for a cart update, the text will differ
   * @param {ConfiguratorRouter.Data} routerData - Reflects the current router state
   * @param {Configurator.Configuration} configuration - Configuration
   * @returns {string} The resource key that controls the button description
   */
  getButtonResourceKey(
    routerData: ConfiguratorRouter.Data,
    configuration: Configurator.Configuration
  ): string {
    if (
      routerData.isOwnerCartEntry &&
      configuration.isCartEntryUpdateRequired
    ) {
      return 'configurator.addToCart.buttonUpdateCart';
    } else if (
      routerData.isOwnerCartEntry &&
      !configuration.isCartEntryUpdateRequired
    ) {
      return 'configurator.addToCart.buttonAfterAddToCart';
    } else {
      return 'configurator.addToCart.button';
    }
  }

  /**
   * Triggers action and navigation, both depending on the context. Might result in an addToCart, updateCartEntry,
   * just a cart navigation or a browser back navigation
   * @param {Configurator.Configuration} configuration - Configuration
   * @param {ConfiguratorRouter.Data} routerData - Reflects the current router state
   */
  onAddToCart(
    configuration: Configurator.Configuration,
    routerData: ConfiguratorRouter.Data
  ): void {
    const pageType = routerData.pageType;
    const configuratorType = configuration.owner.configuratorType;
    const isOverview = pageType === ConfiguratorRouter.PageType.OVERVIEW;
    const isOwnerCartEntry =
      routerData.owner.type === CommonConfigurator.OwnerType.CART_ENTRY;
    const owner = configuration.owner;

    const currentGroup = configuration.interactionState.currentGroup;
    if (currentGroup) {
      this.configuratorGroupsService.setGroupStatusVisited(
        configuration.owner,
        currentGroup
      );
    }
    this.container$
      .pipe(
        filter((cont) => !cont.hasPendingChanges),
        take(1)
      )
      .subscribe(() => {
        if (isOwnerCartEntry) {
          if (configuration.isCartEntryUpdateRequired) {
            this.configuratorCartService.updateCartEntry(configuration);
          }

          this.performNavigation(
            configuratorType,
            owner,
            false,
            isOverview,
            configuration.isCartEntryUpdateRequired ?? false
          );
          if (configuration.isCartEntryUpdateRequired) {
            this.configuratorCommonsService.removeConfiguration(owner);
          }
        } else {
          this.configuratorCartService.addToCart(
            owner.id,
            configuration.configId,
            owner
          );

          this.configuratorCommonsService
            .getConfiguration(owner)
            .pipe(
              filter(
                (configWithNextOwner) =>
                  configWithNextOwner.nextOwner !== undefined
              ),
              take(1)
            )
            .subscribe((configWithNextOwner) => {
              //See preceeding filter operator: configWithNextOwner.nextOwner is always defined here
              const nextOwner =
                configWithNextOwner.nextOwner ??
                ConfiguratorModelUtils.createInitialOwner();

              this.performNavigation(
                configuratorType,
                nextOwner,
                true,
                isOverview,
                true
              );

              // we clean up the cart entry related configuration, as we might have a
              // configuration for the same cart entry number stored already.
              // (Cart entries might have been deleted)

              // we do not clean up the product bound configuration yet, as existing
              // observables would instantly trigger a re-create.
              // Cleaning up this obsolete product bound configuration will only happen
              // when a new config form requests a new observable for a product bound
              // configuration

              this.configuratorCommonsService.removeConfiguration(nextOwner);
            });
        }
      });
  }

}

