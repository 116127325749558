import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { CartType, ProductType } from 'src/app/shared/models/cartType.models';
import {
  AccessRoleType,
  UserRoleService,
} from 'src/app/shared/services/user-role.service';
/* import { Item } from '../cart-item/cart-item.component';
import { SharedCartService } from '../shared-cart.service'; */
import { ActiveCartFacade, CartVoucherFacade } from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import {
  REGULAR_PATTERN,
  testRegex,
} from 'src/app/core/generic-validator/regular-expressions';
import { Item } from '../waygate-cart-item/waygate-cart-item.component';
import { SharedCartService } from 'src/app/feature/cart/cart-shared/shared-cart.service';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import {
  GtmEvents,
  ItemListTypeEnum,
} from 'src/app/shared/enums/gtm.enum';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';
import {
  Ecommerce,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import { BuyCheckoutService } from 'src/app/feature/checkout/buy-checkout/service/buy-checkout.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-waygate-cart-order-summary',
  templateUrl: './waygate-cart-order-summary.component.html',
  styleUrls: ['./waygate-cart-order-summary.component.scss'],
})
export class WaygateCartOrderSummaryComponent implements OnInit {
  @Input()
  cart$: Observable<any>;

  entries: Item[];

  @Input()
  cart;

  @Input()
  userType: string;

  @Output()
  scrollToEntry: EventEmitter<any> = new EventEmitter();

  @Input() items: any[];

  cartId$: Observable<string> = this.activeCartFacade.getActiveCartId();
  orderType = 'For Resale';
  cartId: string;
  maxDate

  consolidatedShipmentSubscription: any;
  isPartialSubscription: any;
  coupon = '';
  couponApplied = false;
  couponErrorMsg = false;
  noValueCouponMsg = false;

  isDeliveryErrorSubscription: any;
  deliveryErrors: any[] = [];

  soldToSelected: boolean;
  cartType = CartType;
  availabilityFlag: any;
  getGuestEmailAddress = '';
  guestEmailSubscription: Subscription;
  currentUserAccess$ = this.userRoleService.currentUserRole;
  userRoleEnum = AccessRoleType;
  showLoading: boolean = false;
  isButtonDisabled: boolean = false;
  productLine: string;
  public productType = ProductType;
  nonFilmProduct: any[] = [];
  filmProduct: any[] = [];
  constructor(
    protected routingService: RoutingService,
    private router: Router,
    private sharedCartService: SharedCartService,
    private activeCartFacade: ActiveCartFacade,
    private globalMessageService: GlobalMessageService,
    private actions$: Actions,
    private cartVoucherFacade: CartVoucherFacade,
    private cdRef: ChangeDetectorRef,
    private translate: TranslationService,
    private userRoleService: UserRoleService,
    public sanitizer: DomSanitizer,
    private custAccService: CustomerAccountService,
    private buyCheckoutService: BuyCheckoutService,
    public datepipe: DatePipe,
    private googleTagManagerService: GoogleTagManagerService
  ) {
    this.sharedCartService.isCheckAvailability$.subscribe((res) => {
      this.availabilityFlag = res;
    });
  }

  ngOnInit() {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });

    this.cartId$.subscribe((res) => {
      this.cartId = res;
    });
    this.isButtonDisabled =
      this.cart.isMinOrderQtyProductExists ||
      ((this.cart?.cartType == this.cartType.Typ3 ||
        this.cart?.cartType == this.cartType.Typ1) &&
        !this.cart.enduserAddress);
    this.setFilmAndNonFlimItems();
  }
  ngOnChanges() {
    this.filmProduct = [];
    this.nonFilmProduct = [];
    this.isButtonDisabled =
    this.cart.isMinOrderQtyProductExists ||
    ((this.cart?.cartType == this.cartType.Typ3 ||
      this.cart?.cartType == this.cartType.Typ1) &&
      !this.cart.enduserAddress);
    this.setFilmAndNonFlimItems();
  }
  setFilmAndNonFlimItems() {
    //Setting only unique entries into the film & non-film products list from cart entries
    if (this.cart?.entries) {
      this.filmProduct = this.cart.entries
        .filter(item => item.productType === this.productType.Typ3)
        .filter((item, index, self) =>
          index === self.findIndex(t => t.product.code === item.product.code));
      this.nonFilmProduct = this.cart.entries
        .filter(item => item.productType !== this.productType.Typ3)
        .filter((item, index, self) =>
          index === self.findIndex(t => t.product.code === item.product.code));
    }
  }
  getTranslatedText(key) {
    let message;
    this.translate.translate(key).subscribe((res) => {
      message = res;
    });
    return message;
  }
  applyCoupon() {
    this.coupon = testRegex(
      this.sanitizer.sanitize(SecurityContext.HTML, this.coupon),
      REGULAR_PATTERN.alphaNumericWithSpecialCharater
    );
    if (!this.coupon) return false;
    this.cartVoucherFacade.addVoucher(this.coupon, this.cartId);
    this.actions$.pipe(ofType(CartActions.CART_ADD_VOUCHER_SUCCESS)).subscribe(
      (success: any) => {
        this.couponApplied = true;
        this.coupon = success.payload?.voucherId;

        this.cdRef.detectChanges();
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      }
    );
    this.actions$.pipe(ofType(CartActions.CART_ADD_VOUCHER_FAIL)).subscribe(
      (err: any) => {
        this.couponErrorMsg = false;
        this.noValueCouponMsg = false;
        const couponErr = err.payload.error.details;
        if (couponErr[0].message == 'coupon.code.expectationNotMet.provided') {
          this.noValueCouponMsg = true;
        } else {
          this.couponErrorMsg = true;
        }
        this.couponApplied = false;
        this.coupon = '';

        this.cdRef.detectChanges();
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      }
    );
  }
  proceedToCheckout() {
    if (
      this.cart.isMinOrderQtyProductExists ||
      (this.isButtonDisabled && !this.cart.enduserAddress)
    ) {
      return false;
    }
    var checkoutNativationPath = `/${this.productLine}/checkout`;

    if (this.userType !== 'current') {
      this.sharedCartService.validateForm('validateEmail');
    }

    const processMaxDate = (maxDate: string | null, isFilm: boolean) => {
      // Skip API call if maxDate is invalid or "No estimate available"
      if (!moment(maxDate, "DD-MMM-YYYY", true).isValid() || maxDate === "No estimate available") {
        return;
      }

      maxDate = this.datepipe.transform(maxDate, 'dd-MM-yyyy');

      const requestFn = isFilm ? this.buyCheckoutService.requsetDateForFilm : this.buyCheckoutService.requsetDateForNonFilm;
      requestFn.call(this.buyCheckoutService, this.cartId, maxDate).subscribe(
        (res) => { },
        (error) => {
          this.getTranslatedText('errors.errorMessage');
        }
      );
    };

    const getMaxShipDate = (products: any[]): string | null => {
      if (!products?.length) return null;

      return products
        .filter(item => item?.estShipData?.[0]?.shipDate !== "No estimate available")
        .sort((a, b) => new Date(b?.estShipData?.[0]?.shipDate).getTime() - new Date(a?.estShipData?.[0]?.shipDate).getTime())
      [0]?.estShipData?.[0]?.shipDate ?? null;
    };
    const processEntries = () => {
      if (this.filmProduct?.length > 1) {
        const maxDate = getMaxShipDate(this.filmProduct);
        processMaxDate(maxDate, true);
      }
      if (this.nonFilmProduct?.length > 1) {
        const maxDate = getMaxShipDate(this.nonFilmProduct);
        processMaxDate(maxDate, false);
      }
      if (this.cart?.entries?.length === 1) {
        const singleProduct = this.cart.entries[0];
        const maxDate = singleProduct?.estShipData?.[0]?.shipDate ?? null;
        processMaxDate(maxDate, singleProduct.productType === this.productType.Typ3);
      }
    };

    if (this.cart.cartType === CartType.Typ3) {
      processEntries();
    } else {
      if (this.filmProduct?.length > 1 || this.nonFilmProduct?.length > 1) {
        processEntries();
      } else if (this.cart?.entries?.length === 1) {
        const singleProduct = this.cart.entries[0];
        const maxDate = singleProduct?.estShipData?.[0]?.shipDate ?? null;
        processMaxDate(maxDate, singleProduct.productType === this.productType.Typ3);
      }
    }

    if (
      this.cart?.cartType == this.cartType.Typ1 ||
      this.cart?.cartType == this.cartType.Typ3
    ) {
      if (this.cart?.enduserAddress && !this.availabilityFlag?.availibility) {
        this.setGTMTagsForCheckoutBegin();
        this.router.navigate([checkoutNativationPath]);
      } else if (!this.cart?.enduserAddress) {
        this.globalMessageService.add(
          this.getTranslatedText('buyCart.addEndUserAddress'),
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      } else if (this.availabilityFlag?.availibility) {
        this.globalMessageService.add(
          this.getTranslatedText('buyCart.issueAtLine') +
          this.availabilityFlag?.lineNo,
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      }
    } else if (this.availabilityFlag?.availibility) {
      this.globalMessageService.add(
        this.getTranslatedText('buyCart.issueAtLine') +
        this.availabilityFlag?.lineNo,
        GlobalMessageType.MSG_TYPE_ERROR,
        5000
      );
      window.scrollTo(0, 0);
    } else if (this.cart.isMinOrderQtyProductExists) {
      this.globalMessageService.add(
        this.getTranslatedText('buyCart.minOrderErrorAtCheckout'),
        GlobalMessageType.MSG_TYPE_ERROR,
        5000
      );
      window.scrollTo(0, 0);
    } else if (this.userType !== 'current' && !this.getGuestEmailAddress) {
      this.guestEmailSubscription =
        this.sharedCartService.getGuestEmailAddress.subscribe(
          (guestEmailAddress) => {
            if (guestEmailAddress) {
              this.getGuestEmailAddress = guestEmailAddress;
            }
          }
        );
      if (this.getGuestEmailAddress !== '') {
        this.saveGuestEmail();
      }
    } else {
      if (this.userType !== 'current' && this.getGuestEmailAddress) {
        this.saveGuestEmail();
      } else {
        this.setGTMTagsForCheckoutBegin();
        this.router.navigate([checkoutNativationPath]);
      }
    }
  }

  saveGuestEmail() {
    this.showLoading = true;
    let guestEmailObj = {
      email: this.getGuestEmailAddress,
    };
    this.sharedCartService.saveGuestEmail(this.cartId, guestEmailObj).subscribe(
      (data) => {
        this.showLoading = false;
        this.sharedCartService.setGuestEmailAddress('');
        this.router.navigate(['checkout']);
      },
      (error) => {
        this.showLoading = false;
        this.globalMessageService.add(
          this.getTranslatedText('buyCart.errorMessage'),
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      }
    );
  }
  removeCoupon(couponCode) {
    this.cartVoucherFacade.removeVoucher(couponCode, this.cartId);
    this.coupon = '';
    this.couponErrorMsg = false;
    this.noValueCouponMsg = false;
  }
  ngOnDestroy(): void {
    if (this.guestEmailSubscription) {
      this.guestEmailSubscription.unsubscribe();
    }
  }
  getPositiveSilverClause(value) {
    if (value) {
      return Math.abs(value).toFixed(2);
    }
    return 0;
  }

  returnToCart() { }
  selectTerms() { }

  setGTMTagsForCheckoutBegin() {
    let items: any[] = [];
    if (this.items.length > 0) {
      this.items.forEach((item, i) => {
        let eachitem: any = {
          item_id: item?.product?.code,
          item_name: item?.product?.name,
          coupon: '',
          discount: +item?.discountPercentage ? +item?.discountPercentage : '',
          index: i,
          item_brand: this.googleTagManagerService.getItemBrand(),
          item_category: '',
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_variant: '',
          location_id: '',
          affiliation: this.googleTagManagerService.getItemBrand(),
          item_list_id: ItemListTypeEnum.Cart,
          item_list_name: ItemListTypeEnum.Cart,
          price: item?.netSellingPrice?.value,
          quantity: item?.quantity,
        };
        items.push(eachitem);
      });
    }

    let checkoutBeginEcommerce: Ecommerce = {
      currency: this.cart?.currencyIso,
      value: this.cart?.totalPrice?.value ? this.cart?.totalPrice?.value : '',
      coupon: this.cart?.appliedCouponCodes
        ? this.cart?.appliedCouponCodes[0]
        : '',
      items: items,
    };

    let checkoutBeginDataLayer: GTMDataLayer = {
      store: this.googleTagManagerService.getItemBrand(),
      ecommerce: checkoutBeginEcommerce,
      event: GtmEvents.BeginCheckout,
    };

    this.googleTagManagerService.sendEvent(checkoutBeginDataLayer);
  }
}
