import { Component, Input } from '@angular/core';
import { OrderStatusName } from 'src/app/shared/models/status/order-status.model';
import { LaunchDialogService } from '@spartacus/storefront';
import {  take } from 'rxjs/operators';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
@Component({
  selector: 'app-waygate-order-product',
  templateUrl: './waygate-order-product.component.html',
  styleUrls: ['./waygate-order-product.component.scss'],
})
export class WaygateOrderProductComponent {
  @Input() product;
  @Input() userType;

  constructor(private launchDialogService: LaunchDialogService){}

  getClass = (orderStatus) => {
    return orderStatus.replace(/\s/g, '').replace(/\&/g, '');
  };

  /**
   * @description conpaire the selected status and if it is shipped the return true otherwise false
   * @returns boolean true/false
   */

  get statusIsShipped(): boolean {
    return [
      OrderStatusName.SHIPPED,
      OrderStatusName.INVOICED,
    ].includes(this.product?.status)
      ? true
      : false;
  }

  getUnitNetPrice(product) {
    if (product?.netPrice) {
      if (product?.qty > 1) {
        return Number(product?.netPrice / product.qty).toFixed(2);
      } else {
        return Number(product?.netPrice).toFixed(2);
      }
    }
  }

  getTotalPrice(totalPrice) {
    return Number(totalPrice).toFixed(2);
  }

  public openModal(){
    const componentdata = {
      item :this.product,
      userType: this.userType,
    };
    const viewConfigModal = this.launchDialogService.openDialog(
      DS_DIALOG.CONFIGURATOR_OVERVIEW_MODAL,
      undefined,
      undefined,
      componentdata
    );
    if (viewConfigModal) {
      viewConfigModal.pipe(take(1)).subscribe(() => {
      });
    }
  }
}
