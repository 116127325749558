import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { QuickOrderService } from '../quick-order.service';
import { GlobalMessageType } from '@spartacus/core';
import { LandingPagesService } from 'src/app/feature/landing/landing-pages.service';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { Actions, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
import { LaunchDialogService } from '@spartacus/storefront';
import * as moment from 'moment';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import {
  Ecommerce,
  EcommerceItem,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import {
  GtmEvents,
  ItemListTypeEnum,
} from 'src/app/shared/enums/gtm.enum';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';

@Component({
  selector: 'app-quick-order-products',
  templateUrl: './quick-order-products.component.html',
  styleUrls: ['./quick-order-products.component.scss'],
})
export class QuickOrderProductsComponent {
  @Input() sharedData: any;
  @Input() deletedRow: number;
  displayDataScource: boolean = true;
  bulkUploadList = [];
  validatedBulkUploadList: any;
  globalMessageService: any;
  currency: any;
  currencySymbol: any;
  cartId: any;
  priceParams = {};
  checkPriceValue: any;
  @ViewChild('searchFocusInput') searchFocusInput: ElementRef;
  pageSizes = [10, 20, 50, 100];
  displayViewBreakUpItem: any;
  startRange = 1;
  totalCount = 0;
  currentPageSize = 10;
  endRange = this.currentPageSize;
  totalPages: number;
  currentPage: number = 1;
  displayedColumns: string[] = [
    'select',
    'partNum',
    'description',
    'quantity',
    'price',
  ];
  selection = new SelectionModel<any>(true, []);
  selectedRowsCount: number;
  totalRowCount: number;
  showLoader: boolean = false;
  selectedRows = [];
  eventListData = [];
  @Output() enableAddToCart = new EventEmitter<any>();
  breakUp = false;
  productLine: string;
  contactUsUrl:string;
  constructor(
    private landingPageService: LandingPagesService,
    private multiCartFacade: MultiCartFacade,
    private actions$: Actions,
    private router: Router,
    private launchDialogService: LaunchDialogService,
    private quickOrderService: QuickOrderService,
    private custAccService: CustomerAccountService,
    private gtmService: GoogleTagManagerService
  ) {}
  ngOnInit(): void {
    this.displayDataScource = false;
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.contactUsUrl =  `/${this.productLine}/contactus`;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharedData && this.sharedData !== undefined) {
      if (this.selection.selected.length > 0) {
        this.selection.clear();
      }
      this.cartId = this.sharedData?.cartId;
      this.currency = this.sharedData?.data?.currencyISO;
      this.currencySymbol = this.sharedData?.data?.currencyFormattedValue;
      this.bulkUploadList = [];
      this.validatedBulkUploadList =
        this.sharedData?.data?.validatedBulkUploadList;
      this.validatedBulkUploadList = this.validatedBulkUploadList.filter(
        (product) => product?.productAccessData?.isBuy
      );
      this.initPagination();
      if (this.validatedBulkUploadList?.length > 0) {
        this.selectedRows = this.validatedBulkUploadList?.filter(
          (item) => item.status === 'Check Price' || item.status === 'Validated'
        );
        this.displayDataScource = true;
        this.selectedRowsCount = this.selectedRows.length;
        this.selection.select(...this.selectedRows);
      }
    } else if (changes.deletedRow && this.deletedRow !== undefined) {
      if (
        this.deletedRow >= 0 &&
        this.deletedRow < this.bulkUploadList.length
      ) {
        this.bulkUploadList.splice(this.deletedRow, 1);
        this.selection.deselect(this.bulkUploadList[this.deletedRow]);
      }
    } else {
      this.displayDataScource = false;
    }
  }
  pageSizeChanged(event) {
    this.currentPageSize = parseInt(event?.target?.value);
    this.endRange =
      this.currentPageSize > this.validatedBulkUploadList?.length
        ? this.validatedBulkUploadList?.length
        : this.currentPageSize;
    this.initPagination();
  }
  pageSelected(pageNumber) {
    const calculateStartRange = (pageNumber - 1) * this.currentPageSize + 1;
    if (calculateStartRange > 0 && pageNumber <= this.totalPages) {
      this.currentPage = parseInt(pageNumber);
      this.startRange = calculateStartRange;
      this.endRange = this.startRange + this.currentPageSize - 1;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.validatedBulkUploadList.length;
    return numSelected === numRows;
  }

  toggleSelectionAll(event) {
    if (event.checked) {
      this.validatedBulkUploadList.forEach((row) => {
        if (row?.productAccessData?.isBuy) {
          this.selection.select(row);
          this.selectedRowsCount = this.selection.selected.length;
          this.enableAddToCart.emit(this.selectedRowsCount > 0 ? true : false);
        }
      });
    } else {
      this.selectedRowsCount = 0;
      this.selection.clear();
    }
  }

  toggleSelection(row: any) {
    const index = this.selectedRows.indexOf(row);
    if (index === -1 && row?.productAccessData?.isBuy) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(index, 1);
    }
    this.selectedRowsCount = this.selectedRows.length;
    this.selection.toggle(row);
    this.enableAddToCart.emit(this.selectedRowsCount > 0 ? true : false);
  }
  initPagination() {
    this.totalCount = this.validatedBulkUploadList?.length;
    this.totalPages = Math.ceil(this.totalCount / this.currentPageSize);
    this.startRange = 1;
    this.currentPage = 1;
  }

  addToCart() {
    const unique = [];
    this.bulkUploadList = this.selection.selected
      ?.filter((element) => element?.productAccessData?.isBuy)
      ?.map((element, i) => {
        this.eventListData.push(element);
        return {
          lineNo: i,
          availableStock: element.quantity,
          partNum: element.partNum,
          description: element.description,
          price: element?.unitPrice?.value,
          status:
            element.status === 'Check Price'
              ? (element.status = 'Validated')
              : element.status,
          quantity: element.quantity,
          productSNo: element.productSNo,
          configurationflag: '',
          actualQuantity: element.quantity,
          showPrice: element.status === 'Validated' ? true : false,
          disabled: element?.productAccessData?.isBuy ? false : true,
        };
      });
    let param = {
      callingsourceinfo: 'Cart Validate Page',
      cartDetailTable_length: 50,
      bulkUploadList: this.bulkUploadList,
      currencyIso: this.currency,
      currencySymbol: this.currencySymbol,
    };
    this.landingPageService.addBulkOrder(this.cartId, param).subscribe(
      (res) => {
        this.multiCartFacade.loadCart({
          cartId: this.cartId,
          userId: 'current',
          extraData: {
            active: true,
          },
        });
        this.actions$
          .pipe(ofType(CartActions.LOAD_CART_SUCCESS))
          .pipe(take(1))
          .subscribe(
            (r) => {
              this.showLoader = false;
              this.pushEventToGtm();
              this.quickOrderService.setNavigation(true);
              this.router.navigate(['/waygate/cart']).finally(() => {
                this.quickOrderService.setNavigation(false);
              });
            },
            (e) => {
              this.showLoader = false;
              this.globalMessageService.add(
                'There is an error occured while adding these products into cart',
                GlobalMessageType.MSG_TYPE_ERROR,
                5000
              );
            }
          );
      },
      (error) => {
        this.showLoader = false;
      }
    );
  }

  getRealTimePrice(data, index) {
    this.priceParams = {
      lineNo: index,
      partNum: data.partNum,
      qty: data.quantity ? data.quantity : 1,
    };
    this.landingPageService
      .checkPrice(this.cartId, this.priceParams)
      .subscribe((res: any) => {
        this.bulkUploadList = this.bulkUploadList.map((item) => {
          if (item.lineNo === index && res.status === 'Validated') {
            item.price = res?.unitPrice?.value;
            item.status = res?.status;
            item.showPrice = true;
          }
          return item;
        });
        this.bulkUploadList = [...this.bulkUploadList];
      });
  }

  removeProducts() {
    const removeProductsDialog = this.launchDialogService.openDialog(
      DS_DIALOG.REMOVE_PRODUCTS,
      undefined,
      undefined,
      {}
    );
    if (removeProductsDialog) {
      removeProductsDialog.pipe(take(1)).subscribe((value) => {
        if (value && value.instance?.reason) {
          this.validatedBulkUploadList = this.validatedBulkUploadList.filter(
            (row) => !this.selection.isSelected(row)
          );
          this.selection.clear();
          if (this.validatedBulkUploadList.length > 0) {
            this.initPagination();
            this.selectedRows = this.validatedBulkUploadList?.filter(
              (item) => !item.disabled && item.showPrice
            );
            this.selectedRowsCount = this.selectedRows.length;
          } else {
            this.displayDataScource = false;
          }
        }
      });
    }
  }
  createArray(N) {
    let newArr = [];
    for (let i = 1; i <= N; i++) {
      newArr.push(i);
    }
    return newArr;
  }
  getPageArray() {
    return this.createArray(this.totalPages);
  }
  openViewBreakup() {
    this.breakUp = true;
  }
  closeMenu() {
    this.breakUp = false;
  }
  viewBreakUp(item) {
    this.displayViewBreakUpItem = item;
  }
  compareQty(row) {
    if (
      parseInt(
        row?.priceAvailabilityData?.availabilityDetails[0]?.actualStockQty
      ) < row?.quantity
    ) {
      return true;
    }
    return false;
  }
  notAvailibility(row) {
    if (
      parseInt(
        row?.priceAvailabilityData?.availabilityDetails[0]?.actualStockQty
      ) == 0
    ) {
      return true;
    }
    return false;
  }

  pushEventToGtm() {
    let price = 0;
    const eventData: Ecommerce = {
      currency: this.currency,
      value: price ? price : '',
      items: this.eventListData.map((product, index): EcommerceItem => {
        return {
          item_id: product.partNum,
          quantity: product.quantity,
          item_name: product.description,
          price: product.priceAvailabilityData.yourPrice.value,
          discount: product.priceAvailabilityData.discountPercentage,
          item_list_id: ItemListTypeEnum.QuickOrder,
          item_list_name: ItemListTypeEnum.QuickOrder,
          item_brand: this.gtmService.getItemBrand(),
          item_category: '',
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_variant: '',
          index: index,
        };
      }),
    };
    const event: GTMDataLayer = {
      event: GtmEvents.AddToCart,
      store: this.gtmService.getItemBrand(),
      ecommerce: eventData
    };
    this.gtmService.sendEvent(event);
  }
}
