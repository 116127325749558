
<ng-container *ngIf="product$ | async as product">
  <div class="configure-page-headerbar">
  <div class="container">
    <div class="left-items">
      <span id="title">Configure Product</span>
      <span id="pipe">|</span>
      <span id="prod-title">{{product.name}}</span>
    </div>
    <div class="right-items" *ngIf="configuration$ | async as configuration">
      <button  (click)="resetData(configuration.configId)" 
      [ngClass]="{'reset-btn':(!configuration.disableResetConfigFlag),
      'reset-container-disabled':(configuration.disableResetConfigFlag)}">
        <mat-icon>refresh</mat-icon>
        <div class="reset-font">Reset</div>
      </button>
      <!-- <button id="download-btn">
        
      <div class="download-csv-font">
      <span><img src="../../../../../assets/img/Download_csv.svg"></span>
      <div class="download-csv-label">Download CSV</div>  
      </div>
      </button> -->
    </div>

  </div>
</div>
</ng-container>
<br><br>