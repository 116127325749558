

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductScope, ProductService } from '@spartacus/core';
import {
  CommonConfigurator,
  ConfiguratorRouterExtractorService,
} from '@spartacus/product-configurator/common';
import { Configurator, ConfiguratorCommonsService } from '@spartacus/product-configurator/rulebased';

import { ICON_TYPE } from '@spartacus/storefront';

import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/http/api.service';


@Component({
  selector: 'cx-configurator-product-title',
  templateUrl: './configurator-product-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls:['./configurator-product-title.component.scss']
})
export class  ConfiguratorProductTitleComponent {
  constructor(
    protected configuratorCommonsService: ConfiguratorCommonsService,
    protected configRouterExtractorService: ConfiguratorRouterExtractorService,
    protected productService: ProductService,
    private router:Router,
    private apiService: ApiService
  ) {}
  product$: Observable<
    Product
  > = this.configRouterExtractorService.extractRouterData().pipe(
    switchMap((routerData) =>
      this.configuratorCommonsService.getConfiguration(routerData.owner)
    ),
    map((configuration) => {
      switch (configuration.owner.type) {
        case CommonConfigurator.OwnerType.PRODUCT:
        case CommonConfigurator.OwnerType.CART_ENTRY:
          return configuration.productCode;
        case CommonConfigurator.OwnerType.ORDER_ENTRY:
          return configuration.overview.productCode;
      }
    }),
    switchMap((productCode) => this.productService.get(productCode))
  );

  configuration$: Observable<
    Configurator.Configuration
  > = this.configRouterExtractorService.extractRouterData().pipe(
    switchMap((routerData) => {
      return this.configuratorCommonsService.getConfiguration(routerData.owner);
    })
  );

    resetData(configId:any){
        
        
        this.resetConfiguration(configId).subscribe(
          (success:any)=>{
            
          },
          (error:any)=>{
            
          },
          ()=>{
            window.location.reload();
          }
        );
        
    }

    resetConfiguration(configurationId:any){
      const params=['ccpconfigurator','reset',configurationId];
      const apiUrl=this.apiService.constructUrl(params);
     return this.apiService.postData(apiUrl,{},{responseType: 'text'});
    }
}
