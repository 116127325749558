import { Component, ViewChild } from '@angular/core';
import { QuickOrderService } from './quick-order.service';
import { QuickOrderProductsComponent } from './quick-order-products/quick-order-products.component';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { productLineCode, productLineName } from 'src/app/shared/products-constants';

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss'],
})
export class QuickOrderComponent {
  sharedData: any;
  textChange;
  enableAddToCart = false;
  showLoader: boolean = false;
  showInvalidEntryMsg: boolean = false;
  showObsoluteMsg: boolean = false;
  deletedRow: number;
  productLine:string;
  allProductsUrl:string;
  @ViewChild(QuickOrderProductsComponent, { static: false })
  private quickOrderProductsComponent: QuickOrderProductsComponent;
  constructor(
    private custAccService: CustomerAccountService
  ){}
  ngOnInit(){
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.allProductsUrl = `/${this.productLine}/categories/${productLineCode[this.productLine]}/${productLineName[this.productLine]}`
    });
  }
  handleData(data: any) {
    if (data?.data?.validatedBulkUploadList.length > 0) {
      this.sharedData = data;
      // this.showInvalidEntryMsg = false;
      let filteredList = this.sharedData?.data?.validatedBulkUploadList?.filter(
        (product) => product?.productAccessData?.isBuy
      );
      this.enableAddToCart = filteredList?.filter(item => item.status === 'Check Price' || item.status === 'Validated').length > 0;
      this.showInvalidEntryMsg = this.sharedData?.data?.validatedBulkUploadList?.filter(item => !item.productAccessData?.isBuy).length > 0;
    } else {
      this.sharedData = data;
      this.enableAddToCart = false;
      this.showInvalidEntryMsg = true;
      this.showObsoluteMsg = false;
    }
  }
  addToCart() {
    this.showLoader = true;
    this.quickOrderProductsComponent.addToCart();
  }
  partsCopied(event) {
    this.textChange = event;
  }
  enableCart(data) {
    this.enableAddToCart = data;
  }
  deleteRow(deletedRow: any) {
    this.deletedRow = deletedRow;
  }
}