import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  SecurityContext,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import {
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SpinnerOverlayService } from 'src/app/shared/components/spinner-overlay/spinner-overlay.service';
import { Discounts } from 'src/app/shared/models/discounts.model';
import {
  MultiCartFacade,
  PromotionLocation,
  PromotionResult,
} from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
import {
  REGULAR_PATTERN,
  testRegex,
} from 'src/app/core/generic-validator/regular-expressions';
import { SharedCartService } from 'src/app/feature/cart/cart-shared/shared-cart.service';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';
import {  
  ItemListTypeEnum,
  GtmEvents,
} from 'src/app/shared/enums/gtm.enum';
import {
  Ecommerce,
  EcommerceItem,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import { ProductType } from 'src/app/shared/models/cartType.models';

export interface Item {
  productType?: string;
  product?: any;
  quantity?: any;
  basePrice?: any;
  totalPrice?: any;
  netPrice?: any;
  updateable?: boolean;
  entryNumber?: any;
  listPrice?: any;
  customerRequestedDate?: any;
  selectedLegalEntity?: any;
  availabilityDetail?: any;
  splitByAvailability?: boolean;
  stockAvailability?: boolean;
  isQtyAvailable?: boolean;
  discountDetails?: any;
  deliveryGroupNumber?: any;
  entryNotes?: any;
  salesConversion?: any;
  salesUnit?: any;
  availableAt?: any;
  expressAdder?: any;
  quoteCartEntryType?: string;
  plantAvailableAt?: any;
  expressAdderPercent?: any;
  availabilityDetails?: any;
  estimatedShipDates?: any;
  silverClausePricePercentage?: any;
  silverClausePrice?: any;
  estShipData?: any;
  netSellingPrice?: any;
  subTotalListPrice?: any;
  discountPercentage?: any;
  minOrderQtyError?: any;
  availableQuantity?: any;
  yourPriceDiscount?: any;
  leadTime?: any;
  fullyConfigurePartNumber?: any;
}

export interface CartItemComponentOptions {
  isSaveForLater?: boolean;
  optionalBtn?: any;
}

@Component({
  selector: 'app-waygate-cart-item',
  templateUrl: './waygate-cart-item.component.html',
  styleUrls: ['./waygate-cart-item.component.scss'],
})
export class WaygateCartItemComponent implements OnInit {
  @Input() compact = false;
  @Input() item: Item;
  @Input() readonly = false;
  @Input() quantityControl: FormControl = new FormControl('', [
    Validators.required,
  ]);

  @Output() view = new EventEmitter<any>();

  @Input() promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;

  // TODO: evaluate whether this is generic enough
  @Input() options: CartItemComponentOptions = {
    isSaveForLater: false,
    optionalBtn: null,
  };
  @Input()
  userType: string;

  @Input()
  cart;

  @Input() entryNum = 0;
  @Input() totalEntries;
  @Input() productOpen;

  @Input()
  checkAll: boolean;

  @Input() isPartialShipment: boolean = false;

  @ViewChild('viewBreakupLink')
  viewBreakupLink;

  @ViewChild('breakupContent')
  breakupContent;

  newItem: Item;
  appliedProductPromotions$: Observable<PromotionResult[]>;
  breakUp = false;
  cartNumber = 0;

  cartId = '';
  minDate;
  defaultDate;
  quantityForm = new FormGroup({
    quantity: new FormControl(1),
  });
  maxQuantity: number = 9999;
  quantity: number = 1;
  disableCheckAvailablity: boolean = true;
  showCheckAvalabilityAction = false;
  checkAvailabilityError = false;
  showAvailabilityLoader = false;
  selectedLegalEntity: any = {
    code: '0',
    name: 'Select',
  };
  showCommentsInput = false;
  additionalDetail: boolean = true;
  consEntryErrorIndex = '';
  consEntryErrorSubscription: any;
  showReqShipError = false;
  savingComments = false;
  deliGrpEntryErrorSubscription: any;
  showDeliGrpError = false;
  deliGrpErrorType = '';
  errorEntries = [];
  commentError = false;
  isPartialSubscription: any;
  isShipmentPartial = false;
  deliveryGroupNumber = '';
  deliveryGroupLoader = false;
  showDiffPlantWarning = false;
  msgText = '';
  commentsSaved = false;
  showCommentsNotSavedErr = false;
  breakUpMenu = false;
  productLine: string;
  productCode: any;
  public productType = ProductType;

  @Output()
  selectedEntry: EventEmitter<any> = new EventEmitter();
  temp_cart: any;
  contactUsUrl:string;
  constructor(
    private launchDialogService: LaunchDialogService,
    private globalMessageService: GlobalMessageService,
    private multiCartFacade: MultiCartFacade,
    private sharedCartService: SharedCartService,
    private actions$: Actions,
    private cRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public elRef: ElementRef,
    private translate: TranslationService,
    private spinnerOverlayService: SpinnerOverlayService,
    public sanitizer: DomSanitizer,
    private customerAccService: CustomerAccountService,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    this.renderer.listen(document, 'click', this.bodyClick.bind(this));
  }
  setDefaultDate(){
    if(this.cart?.longestLeadTimeFilm !== undefined && this.item?.productType == this.productType.Typ3) {
      this.defaultDate = new Date(this.cart?.longestLeadTimeFilm);
    }
    if(this.cart?.longestLeadTime !== undefined && this.item?.productType != this.productType.Typ3) {
      this.defaultDate = new Date(this.cart?.longestLeadTime);
    }
  }
  ngOnInit() {
    this.customerAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.contactUsUrl =  `/${productLine}/contactus`;
    });
    if(this.cart?.isShipCompleteOrder){
      this.setDefaultDate();
    } else {
      if (
        this.item.estShipData !== undefined &&
        this.item.estShipData.length > 0
      ) {
        this.minDate = new Date(
          this.item?.estShipData[this.item?.estShipData.length - 1]?.shipDate
        );
        this.defaultDate = new Date(
          this.item?.estShipData[this.item?.estShipData.length - 1]?.shipDate
        );
      } else {
        this.defaultDate = new Date();
        this.defaultDate.setDate(this.defaultDate.getDate() + 5);
      }
    }

    if (this.item) {
      if (this.item.product?.configurable) {
        this.productCode = this.item.fullyConfigurePartNumber
      } else {
        this.productCode = this.item.product?.code
      }
    }
    this.cartNumber = this.entryNum++;
    this.productOpen = true;
    this.cartId = this.userType === 'current' ? this.cart.code : this.cart.guid;
    this.quantityForm.controls.quantity.setValue(this.item.quantity);

    this.quantityForm.valueChanges.subscribe((res: any) => {
      if (!Number.isInteger(res.quantity)) {
        this.quantityForm.controls.quantity.setValue(Math.floor(res.quantity));
      }
      if (res.quantity != this.item.quantity) {
        this.showCheckAvalabilityAction = true;
        this.sharedCartService.setvalidation(true);
        this.msgText = this.getTranslatedText('buyCart.clickCheckAvailablity');
        const obj = {
          availibility: this.showCheckAvalabilityAction,
          lineNo: this.item.entryNumber + 1,
        };
        this.sharedCartService.emitCheckAvailability(obj);
      }
    });
    this.newItem = Object.assign({}, this.item);
    this.newItem.availabilityDetails =
      this.newItem?.availabilityDetails?.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.plant === obj.plant)
      );
    this.msgText = '';

    this.msgText = '';
    if (this.newItem?.stockAvailability === false) {
      this.msgText =
        this.msgText + this.getTranslatedText('buyCart.noShippingDate');
    }

    if (this.newItem?.splitByAvailability === true) {
      this.msgText =
        this.msgText + this.getTranslatedText('buyCart.splitAvailablity');
    }
    this.selectedLegalEntity = this.newItem?.availabilityDetails?.find(
      (element) => element.plant === this.newItem['plant']
    );
    if (this.newItem.entryNotes) {
      this.additionalDetail = false;
    }

    this.deliveryGroupNumber = this.newItem.deliveryGroupNumber
      ? this.newItem.deliveryGroupNumber
      : '';
  }
  getTranslatedText(key) {
    let message;
    this.translate.translate(key).subscribe((res) => {
      message = res;
    });
    return message;
  }
  ngOnDestroy() {
    this.showReqShipError = false;
    this.cRef.detectChanges();
  }

  isProductOutOfStock(product: any) {
    // TODO Move stocklevelstatuses across the app to an enum
    return (
      product &&
      product.stock &&
      product.stock.stockLevelStatus === 'outOfStock'
    );
  }

  removeItem() {
    this.quantityControl.setValue(0);
    this.quantityControl.markAsDirty();
  }

  viewItem() {
    this.view.emit();
  }

  onDateChange(e) {
    this.showCheckAvalabilityAction = true;
    this.sharedCartService.setvalidation(true);
    this.newItem.customerRequestedDate = moment(e).format('YYYY/MM/DD');
  }

  onLegalEntitySelect(e, item) {
    if (e.plant != this.selectedLegalEntity.plant && !e.defaultPlant) {
      this.showDiffPlantWarning = true;
    } else {
      this.showDiffPlantWarning = false;
    }
    this.showCheckAvalabilityAction = true;
    this.sharedCartService.setvalidation(true);
    this.msgText = this.getTranslatedText('buyCart.clickCheckAvailablity');
    const obj = {
      availibility: this.showCheckAvalabilityAction,
      lineNo: item.entryNumber + 1,
    };
    this.sharedCartService.emitCheckAvailability(obj);
  }

  openDeleteDialog(entryNum) {
    const componentData = {
      cart: this.cart,
      userType: this.userType,
      cartId: this.cartId,
    };
    const deleteCartModal = this.launchDialogService.openDialog(
      DS_DIALOG.CART_DELETE_DIALOG,
      undefined,
      undefined,
      componentData
    );

    if (deleteCartModal) {
      deleteCartModal.pipe(take(1)).subscribe((value) => {
        if (value == 'delete') {
          this.deleteCartItem(entryNum, this.cart);
        }
      });
     
      this.launchDialogService.dialogClose.pipe(take(2)).subscribe((value) => {
        if (value == 'delete') {
          this.deleteCartItem(entryNum, this.cart);
        }
      });
    }
  }

  deleteCartItem(entryNum, cart) {
    const temp_cart = cart;
    //added removefromcart event
    const removeFromCartDataLayer: GTMDataLayer = {
      event: GtmEvents.RemoveFromCart,
      store: this.gtmService.getItemBrand(),
      ecommerce: {
        currency: this.item.listPrice?.currencyIso,
        value: this.item.totalPrice?.formattedValue,
        items: [
          {
            item_id: this.item.product.code,
            item_name: this.item.product.name,
            index: entryNum,
            item_brand: this.gtmService.getItemBrand(),
            item_category: "",
            item_category2: "",
            item_category3: "",
            item_category4: "",
            item_category5: "",
            item_variant: "",
            item_list_id: ItemListTypeEnum.Cart,
            item_list_name: ItemListTypeEnum.Cart,
            price: this.item.totalPrice?.formattedValue,
            quantity: this.item.quantity,
          },
        ],
      },
    };
    this.gtmService.sendEvent(removeFromCartDataLayer);
    //ended removefromcart event
    this.multiCartFacade.removeEntry(this.userType, this.cartId, entryNum);
    this.spinnerOverlayService.show('Loading Cart');

    this.actions$
      .pipe(
        ofType(CartActions.CART_REMOVE_ENTRY_SUCCESS),
        map((action: CartActions.CartRemoveEntrySuccess) => action.payload)
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.selectedEntry.emit({
            entryNumber: this.item.entryNumber,
            checked: false,
          });          
          const checkAvailabilityObj = {
            availibility: false,
            lineNo: null,
          };
          this.sharedCartService.emitCheckAvailability(checkAvailabilityObj);
          this.actions$
            .pipe(ofType(CartActions.LOAD_CART_SUCCESS))
            .pipe(take(1))
            .subscribe(
              (s) => {
                this.spinnerOverlayService.hide();
                this.globalMessageService.add(
                  this.getTranslatedText('buyCart.itemRemovedSuccess'),
                  GlobalMessageType.MSG_TYPE_CONFIRMATION,
                  5000
                );
                window.scrollTo(0, 0);
              },
              (error) => {
                this.spinnerOverlayService.hide();
                this.globalMessageService.add(
                  error,
                  GlobalMessageType.MSG_TYPE_ERROR,
                  5000
                );
                window.scrollTo(0, 0);
              }
            );
        },
        (error) => {
          this.globalMessageService.add(
            error,
            GlobalMessageType.MSG_TYPE_ERROR,
            5000
          );
          window.scrollTo(0, 0);
        }
      );
  }

  toggleProduct() {
    this.productOpen = !this.productOpen;
  }
  additionalDetailToggle() {
    this.additionalDetail = !this.additionalDetail;
  }

  checkAvailability() {
    this.showAvailabilityLoader = true;
    const checkAvailabilityObj = {
      availibility: false,
      lineNo: null,
    };
    this.sharedCartService.emitCheckAvailability(checkAvailabilityObj);
    let reqDate = this.newItem.customerRequestedDate;
    if (!!reqDate) {
      reqDate = moment(reqDate, 'YYYY/MM/DD').format('YYYY-MM-DD');
    }
    const Obj = {
      quantity: this.quantityForm.value.quantity,
      defaultPlant: this.selectedLegalEntity?.plant,
      reqDate: reqDate,
    };
    this.sharedCartService
      .checkAvailability(this.cartId, this.newItem.entryNumber, Obj)
      .subscribe(
        (res: any) => {
          if (res) {
            this.multiCartFacade.loadCart({
              cartId: this.cartId,
              userId: this.userType,
              extraData: {
                active: true,
              },
            });
            this.actions$
              .pipe(ofType(CartActions.LOAD_CART_SUCCESS))
              .pipe(take(1))
              .subscribe(
                (r) => {
                  this.showAvailabilityLoader = false;
                  this.showCheckAvalabilityAction = false;
                  this.sharedCartService.setvalidation(false);
                  if (this.errorEntries.length > 0) {
                    for (const e of this.errorEntries) {
                      if (e == this.item.entryNumber) {
                        this.errorEntries.splice(
                          this.errorEntries.indexOf(e),
                          1
                        );
                      }
                    }
                  }

                  this.showReqShipError = false;
                  this.cRef.detectChanges();
                },
                (error) => {
                  this.globalMessageService.add(
                    error,
                    GlobalMessageType.MSG_TYPE_ERROR,
                    5000
                  );
                  window.scrollTo(0, 0);
                }
              );
          } else {
            this.checkAvailabilityError = true;
          }
          this.cRef.detectChanges();
        },
        (error: any) => {
          this.showAvailabilityLoader = false;
          this.checkAvailabilityError = true;
          this.cRef.detectChanges();
          this.globalMessageService.add(
            error,
            GlobalMessageType.MSG_TYPE_ERROR,
            5000
          );
          window.scrollTo(0, 0);
        }
      );
  }

  checkForDuplicateCartEntries(cartItem) {
    const duplicateEntries = [];
    for (const en of this.cart.entries) {
      if (cartItem.entryNumber != en.entryNumber) {
        if (
          cartItem.customerRequestedDate == en.customerRequestedDate &&
          cartItem.selectedPlant?.code == this.selectedLegalEntity?.code
        ) {
          duplicateEntries.push(en.entryNumber);
        }
      }
    }
    return duplicateEntries;
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.uid === c2.uid : c1 === c2;
  }

  getDiscountName(code) {
    let discountName = Discounts[code];
    if (code.indexOf('Action') > -1) {
      discountName = 'Coupon';
    }
    return discountName;
  }

  closeMenu(event) {
    this.breakUp = false;
  }

  getTotalDiscounts(basePrice, netPrice) {
    let totalDisc = '';
    if (basePrice && netPrice) {
      totalDisc =
        basePrice.formattedValue[0] +
        (basePrice.value - netPrice.value).toFixed(2);
    }
    return totalDisc;
  }

  splitEntry() {
    this.actions$
      .pipe(ofType(CartActions.LOAD_CART_SUCCESS), take(1))
      .subscribe(
        (success) => {},
        (error) => {
          this.globalMessageService.add(
            error,
            GlobalMessageType.MSG_TYPE_ERROR,
            5000
          );
          window.scrollTo(0, 0);
        }
      );
  }

  checkEntry(event: any) {
    const checkValue = event.target.checked;
    this.selectedEntry.emit({
      entryNumber: this.item.entryNumber,
      checked: checkValue,
    });
  }

  onCommentsInputBlur(event) {
    if (!this.commentsSaved && this.newItem.entryNotes?.length >= 0) {
      this.showCommentsNotSavedErr = true;
    }
  }

  onSaveCommentsMouseDown(event) {
    if (event.which == 1) {
      event.preventDefault();
      this.saveComments();
    }
  }

  saveComments() {
    this.newItem.entryNotes = testRegex(
      this.sanitizer.sanitize(SecurityContext.HTML, this.newItem.entryNotes),
      REGULAR_PATTERN.alphaNumericWithSpecialCharater
    );
    this.newItem.entryNotes = this.newItem.entryNotes?.substring(0, 204)?.replace(/&#34|&#10/g,'');
    // if (!this.newItem.entryNotes || this.newItem.entryNotes?.length <= 0) {
    //   this.commentError = true;
    //   return;
    // } else {
    //   this.commentError = false;
    // }
    this.savingComments = true;
    const Obj = {
      quantity: this.quantityForm.value.quantity,
      entryNotes: this.newItem.entryNotes,
      defaultPlant: this.selectedLegalEntity?.plant,
    };
    this.sharedCartService
      .addCartItemComments(this.cartId, this.newItem.entryNumber, Obj)
      .subscribe(
        (res: any) => {
          //  if (res) {
          this.commentsSaved = true;
          this.showCommentsNotSavedErr = false;
          this.savingComments = false;
          this.cRef.detectChanges();
          this.multiCartFacade.loadCart({
            cartId: this.cartId,
            userId: this.userType,
            extraData: {
              active: true,
            },
          });
        },
        (error) => {
          this.globalMessageService.add(
            error,
            GlobalMessageType.MSG_TYPE_ERROR,
            5000
          );
          window.scrollTo(0, 0);
        }
      );
  }

  onDeliveryGroupInputBlur() {
    const oldDeliGroup = this.newItem.deliveryGroupNumber;
    if (this.deliveryGroupNumber.length <= 0) {
      this.showDeliGrpError = true;
      this.deliGrpErrorType = '100';
      return;
    }
    this.newItem.deliveryGroupNumber = this.deliveryGroupNumber;

    let errors: any = {};

    if (!errors || errors.entryNumbers.length <= 0) {
    }

    if (errors?.entryNumbers.length > 0) {
      errors.entryNumbers.push(this.newItem.entryNumber);
      this.deliveryGroupNumber = oldDeliGroup;
      this.newItem.deliveryGroupNumber = this.deliveryGroupNumber;
    }

    if (errors.entryNumbers.length <= 0) {
      const cartInput = {
        entries: [this.newItem],
      };
      this.deliveryGroupLoader = true;
    }
  }

  getShipDate(entry) {
    if (!!entry) {
      if (this.isPartialShipment) {
        let dt = entry.estShipData[0];
        if (
          typeof dt?.shipDate === 'string' ||
          dt?.shipDate instanceof String
        ) {
          if (
            dt?.shipDate?.toLowerCase() == 'no estimate available' ||
            dt?.shipDate?.indexOf('estimate') > -1
          ) {
            return this.getTranslatedText('buyCart.noEstimateAvailable');
          }
        }
        if (dt?.shipDate == '01-Jan-2100') {
          return this.getTranslatedText('buyCart.noEstimateAvailable');
        }
        return moment(dt?.shipDate).format('D MMMM, YYYY');
      } else {
        let product = entry.product;
        const defaultPlant = this.newItem?.availabilityDetails?.find(
          (plant) => plant.isDefaultPlant
        );
        if (defaultPlant) {
          return moment(defaultPlant.committedDate).format('D MMMM, YYYY');
        }
      }
    }
  }

  getEarliestPossibleDate(avail) {
    if (
      moment(avail.committedDate).format('DD-MM-YYYY') ==
      moment(this.item.customerRequestedDate).format('DD-MM-YYYY')
    ) {
      return false;
    }
    return true;
  }

  findProductFromCartItems(items) {
    return items.filter((el) => {
      return (
        el.product.code == this.newItem.product.code &&
        el.entryNumber == this.newItem.entryNumber
      );
    });
  }

  bodyClick() {
    if (
      this.viewBreakupLink &&
      !this.viewBreakupLink.nativeElement.contains(event.target) &&
      this.breakupContent &&
      !this.breakupContent.nativeElement.contains(event.target)
    ) {
      this.breakUp = false;
      this.cRef.detectChanges();
    }
  }

  togglePriceBreakup() {
    this.breakUpMenu = !this.breakUpMenu;
  }

  getPositiveSilverClause(value) {
    if (value) {
      return Math.abs(value).toFixed(2);
    }
    return 0;
  }
  increaseQuantity(count: any) {
    this.quantity = count;
    this.disableCheckAvailablity = false;
  }

  decreaseQuantity(count: any) {
    this.quantity = count;
    this.disableCheckAvailablity = false;
  }
  quantityAdded(count: any) {
    this.quantity = count;
    this.disableCheckAvailablity = false;
  }
  getInt(product) {
    if (!!product) {
      const defaultPlant = this.newItem?.availabilityDetails?.find(
        (plant) => plant.isDefaultPlant
      );
      if (defaultPlant) {
        return parseInt(defaultPlant.actualStockQty);
      }
    }
  }
  checkStockAvailablity(product) {
    const qty = this.quantityForm.get('quantity').value;
    let stockAvailable = false;
    if (!!product) {
      const defaultPlant = this.newItem?.availabilityDetails?.find(
        (plant) => plant.isDefaultPlant
      );
      if (defaultPlant) {
        stockAvailable = defaultPlant.actualStockQty >= qty;
      }
    }
    return stockAvailable;
  }

  //Google Analytics
  gtmSelectItemEvent(Item) {
    if (Item) {
      let producitem: EcommerceItem[] = [];
      producitem.push({
        item_id: Item.product?.code,
        item_name: Item.product?.name,
        discount: Item?.discountPercentage ? +Item?.discountPercentage : '',
        index: 0,
        item_brand: this.gtmService.getItemBrand(),
        item_list_id: ItemListTypeEnum.Cart,
        item_list_name: ItemListTypeEnum.Cart,
        price: Item?.discountPrice ? +Item?.discountPrice : '',
        quantity: Item.quantity,
        item_category: '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_variant: '',
      });
      let purchaseEcommerceEcommerce: Ecommerce = {
        item_list_id: ItemListTypeEnum.Cart,
        item_list_name: ItemListTypeEnum.Cart,
        items: producitem,
      };
      let selectItemDataLayer: GTMDataLayer = {
        event: GtmEvents.SelectItem,
        store: this.gtmService.getItemBrand(),
        ecommerce: purchaseEcommerceEcommerce,
      };
      this.gtmService.sendEvent(selectItemDataLayer);
    }
  }
  public openModal(item){
    const configureurl='/configure/vc/cartEntry/entityKey/'+item.entryNumber;
    const componentdata = {
      item :item,
      cart: this.cart,
      userType: this.userType,
      cartId: this.cartId
    };
    const viewConfigModal = this.launchDialogService.openDialog(
      DS_DIALOG.CONFIGURATOR_OVERVIEW_MODAL,
      undefined,
      undefined,
      componentdata
    );
    if (viewConfigModal) {
      viewConfigModal.pipe(take(1)).subscribe((value) => {
          console.log("closing the overview");
      });
      this.launchDialogService.dialogClose.subscribe((value) => {
        if (value == 'update configuration') {
          this.router.navigate([configureurl], {queryParams: {
            forceReload: true,
            resolveIssues: false
          }});
        }
      });
    }
  }
}
