import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DIALOG_TYPE,IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule, provideConfig } from '@spartacus/core';

import { ConfiguratorProductTitleModule } from './product-configurator-title/configurator-product-title.module';
import { ConfiguratorTabBarModule } from './configurator-tab-bar';
import { ConfiguratorGroupMenuModule } from './group-menu';
import { ConfiguratorPreviousNextButtonsModule } from './prev-next-buttons';
import { ConfiguratorExitButtonModule } from './exit-button';
import { ConfiguratorAddToCartButtonModule } from './product-overview/add-to-cart-button';
import { ConfiguratorFormModule } from './form';
import { ConfiguratorGroupModule } from './group';
import { ConfiguratorAttributeCheckboxListModule } from './checkbox-list';
import { ConfiguratorAttributeRadioButtonModule } from './radio-button';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
import { FileUploadModule } from 'src/app/shared/components/file-upload/file-upload.module';
import { ConfiguratorHaveAquestionModelComponent } from './configurator-have-aquestion-model/configurator-have-aquestion-model.component';
import { ConfiguratorAttributeInputFieldModule } from './input-field';

/* import { ConfiguratorFormModule } from './config-form'; */



@NgModule({
  declarations: [
    ConfiguratorHaveAquestionModelComponent
  ],
  exports: [
    ConfiguratorHaveAquestionModelComponent
  ],
  imports: [
    CommonModule,
    KeyboardFocusModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    I18nModule,
    ConfiguratorTabBarModule,
    ConfiguratorProductTitleModule,
    ConfiguratorGroupMenuModule,
    ConfiguratorPreviousNextButtonsModule,
    ConfiguratorExitButtonModule,
    ConfiguratorAddToCartButtonModule,
    ConfiguratorFormModule,
    ConfiguratorGroupModule,
    ConfiguratorAttributeCheckboxListModule,
    ConfiguratorAttributeRadioButtonModule,
    ConfiguratorAttributeRadioButtonModule,
    FileUploadModule,
    ConfiguratorAttributeInputFieldModule
  ],
  providers: [
    provideConfig({
      launch: {
        [DS_DIALOG.HAVE_A_QUESTION_DIALOG]:{
          inlineRoot: true,
          component: ConfiguratorHaveAquestionModelComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        }
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ProductConfigurationModule {}
