import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DsAppState } from 'src/app/store/app.reducer';

import { CustomerAccount, SalesArea } from './store/customer-account.model';
import * as fromCustomerAccountAction from './store/actions/customer-account.action';
import * as fromCustomerAccountSelectors from './store/selectors/customer-account.selector';
import { ApiService } from '../http/api.service';
import {
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  ProductSearchService,
  ProductService,
  StorageSyncType,
  WindowRef,
} from '@spartacus/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CurrentProductService } from '@spartacus/storefront';
import { ProductCategoriesService } from '../product-catalog/services/product-categories.service';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import {
  AllProductLine,
  ProductLineHomePageURL,
  ProductLineStorageKey,
} from 'src/app/shared/enums/availableProductList.enum';
import { OrderTrackingService } from 'src/app/feature/order-tracking/order-tracking.service';

export const GUEST_ACTIVE_SALES_AREA_KEY = 'guest-active-salesArea';
import {
  wayagteGuestSalesId,
  bentlyGuestSalesId,
  panametricsGuestSalesId,
  druckGuestSalesId,
  reuterStokesGuestSalesId,
} from './../../shared/products-constants';

@Injectable({
  providedIn: 'root',
})
export class CustomerAccountService {
  disableChangeAccount = new BehaviorSubject(false);
  getSalesAreaData = new BehaviorSubject(false);
  rmaDsSwitchCartFlag: any;
  sliderSource = new BehaviorSubject(null);
  getSliderState = this.sliderSource.asObservable();
  notificationSliderSource = new BehaviorSubject(null);
  getNotificationSliderState = this.notificationSliderSource.asObservable();
  notificationData$ = new BehaviorSubject(null);

  constructor(
    private store: Store<DsAppState>,
    private apiService: ApiService,
    private occEndpointService: OccEndpointsService,
    protected winRef: WindowRef,
    private commonService: CommonService,
    private productSearchService: ProductSearchService,
    protected currentProductService: CurrentProductService,
    private multiCartFacade: MultiCartFacade,
    private productCatService: ProductCategoriesService,
    private orderTrackingService: OrderTrackingService,
    private router: Router,
    private productService: ProductService,
    private actions$: Actions
  ) {}

  loadCurrentCustomerAccount(): Observable<boolean> {
    return this.store
      .select(fromCustomerAccountSelectors.isCustomerAccountLoaded)
      .pipe(
        tap((isLoaded) => {
          if (!isLoaded) {
            this.store.dispatch(
              fromCustomerAccountAction.GetCurrentCustomerAccount()
            );
          }
        })
      );
  }
  setProductLine(productLine: string) {
    this.winRef.localStorage.setItem(
      ProductLineStorageKey.productLine,
      productLine
    );
    return this.store.dispatch(
      fromCustomerAccountAction.setProductLine({ payload: productLine })
    );
  }

  getProductLine() {
    return this.store.select(fromCustomerAccountSelectors.getProductLine);
  }

  getCurrentCustomerAccount(): Observable<CustomerAccount> {
    return this.store.select(
      fromCustomerAccountSelectors.fetchCurrentCustomerAccount
    );
  }

  updateCurrentCustomerAccount(custAccount: CustomerAccount) {
    return this.store.dispatch(
      fromCustomerAccountAction.UpdateCurrentCustomerAccount({
        payload: custAccount,
      })
    );
  }

  loadFavCustomerAccounts() {
    return this.store.dispatch(
      fromCustomerAccountAction.LoadFavCustomerAccounts()
    );
  }

  getFavCustomerAccounts() {
    return this.store.select(
      fromCustomerAccountSelectors.fetchFavCustomerAccounts
    );
  }

  addCustomerAccToFav(custAccount: CustomerAccount) {
    return this.store.dispatch(
      fromCustomerAccountAction.AddFavCustomerAccount({ payload: custAccount })
    );
  }

  removeCustomerAccFromFav(custAccount: CustomerAccount) {
    return this.store.dispatch(
      fromCustomerAccountAction.RemoveFavCustomerAccount({
        payload: custAccount,
      })
    );
  }

  loadRecentCustomerAccounts() {
    return this.store.dispatch(
      fromCustomerAccountAction.LoadRecentCustomerAccounts()
    );
  }

  getRecentCustomerAccounts() {
    return this.store.select(
      fromCustomerAccountSelectors.fetchRecentCustomerAccounts
    );
  }

  updateAvaiableProductLine(productLine: string[]) {
    this.setAvaiableProductLineToStorage(productLine);
    return this.store.dispatch(
      fromCustomerAccountAction.UpdateAvaiableProductLine({
        payload: productLine,
      })
    );
  }

  searchCustomerAccount(searchKey: string) {
    if (!searchKey) {
      return of([]);
    }
    let url = this.occEndpointService.buildUrl(
      '/users/current/searchSoldToUnit'
    );
    return this.apiService.getData(url, { customerAccountId: searchKey });
  }

  updateSalesArea(salesAreaId, soldToId) {
    const apiUrl = this.occEndpointService.buildUrl(
      '/users/current/updateSoldTo'
    );
    const apiParams = {
      salesAreaId,
      soldToUid: soldToId,
    };

    return this.apiService.putData_options(apiUrl, {}, { params: apiParams });
  }

  loadGuestSalesAreas() {
    return this.store.dispatch(fromCustomerAccountAction.LoadGuestSalesAreas());
  }

  getGuestSalesAreas() {
    return this.store.select(fromCustomerAccountSelectors.fetchGuestSalesAreas);
  }

  getCurrentGuestSalesArea() {
    return this.store.select(
      fromCustomerAccountSelectors.fetchCurrentGuestSalesArea
    );
  }

  updateGuestSalesArea(salesArea: SalesArea) {
    this.setGuestActiveSalesAreaToStorage(salesArea);
    return this.store.dispatch(
      fromCustomerAccountAction.UpateGuestSalesArea({ payload: salesArea })
    );
  }

  setAvaiableProductLineToStorage(productLine: string[]) {
    this.commonService.persistToStorage(
      ProductLineStorageKey.avaiableProductLine,
      productLine,
      this.commonService.getStorage(StorageSyncType.LOCAL_STORAGE, this.winRef)
    );
  }

  getAvaiableProductLineToFromStorage() {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    return this.commonService.readFromStorage(
      storage,
      ProductLineStorageKey.avaiableProductLine
    );
  }

  setGuestActiveSalesAreaToStorage(salesArea: SalesArea) {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    this.commonService.persistToStorage(
      GUEST_ACTIVE_SALES_AREA_KEY,
      salesArea,
      storage
    );
  }

  getGuestActiveSalesAreaFromStorage() {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    return this.commonService.readFromStorage(
      storage,
      GUEST_ACTIVE_SALES_AREA_KEY
    );
  }

  removeGuestActiveFromStorage() {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    this.commonService.removeFromStorage(storage, GUEST_ACTIVE_SALES_AREA_KEY);
  }

  refreshPostGuestSalesAreaSwitch(isGuestPdp?) {
    this.multiCartFacade.createCart({
      userId: OCC_USER_ID_ANONYMOUS,
      extraData: {
        active: true,
      },
    });
    this.actions$
      .pipe(ofType(CartActions.CREATE_CART_SUCCESS), take(1))
      .subscribe((cartResponse: any) => {
        if (cartResponse?.payload?.cartId) {
          this.multiCartFacade.loadCart({
            cartId: cartResponse.payload.cartId,
            userId: OCC_USER_ID_ANONYMOUS,
            extraData: {
              active: true,
            },
          });
          this.loadGuestSalesAreas();
          this.productSearchService.search(
            ':relevance:allCategories:ECOM_LVL0_00000000',
            { pageSize: 50 }
          );
          this.currentProductService
            .getProduct()
            .subscribe((res) => {
              if (res) {
                // this.productService.reload(res.code)
              }
            })
            .unsubscribe();

          this.productCatService.loadDefaultProductCategories(
            OCC_USER_ID_ANONYMOUS
          );
          if (isGuestPdp) {
            window.location.reload();
          } else {
            this.refreshCurrentRoute();
          }
        }
      });
  }

  refreshPostCustomAccSwitch(isswitchAccount?) {
    // Update Cart
    this.multiCartFacade.createCart({
      userId: OCC_USER_ID_CURRENT,
      extraData: {
        active: true,
      },
    });

    this.actions$
      .pipe(ofType(CartActions.CREATE_CART_SUCCESS), take(1))
      .subscribe((cartResponse: any) => {
        if (cartResponse?.payload?.cartId) {
          this.multiCartFacade.loadCart({
            cartId: cartResponse.payload.cartId,
            userId: OCC_USER_ID_CURRENT,
            extraData: {
              active: true,
            },
          });
          // storing number of cart in localstorage based on account
          localStorage.setItem(
            'numberOfCart',
            cartResponse.payload.cart.totalItems
          );
          this.loadFavCustomerAccounts();
          this.loadRecentCustomerAccounts();
          // Update PLP results
          this.productSearchService.search(
            ':relevance:allCategories:ECOM_LVL0_00000000',
            { pageSize: 50 }
          );
          //DE146739: Navigate to HomePage / WagatePage based on Sales Group.
          if (isswitchAccount != 'switchAccount') {
            this.getCurrentCustomerAccount()
              .pipe(take(1))
              .subscribe((cutmrAcount) => {
                // this.updateAvaiableProductLine(
                //   cutmrAcount?.visibleCategories || []
                // );
                // verify notification is exist if not fetch list here
                this.verifyAndFetchNotification(cutmrAcount?.uid);
                if (
                  cutmrAcount?.visibleCategories &&
                  cutmrAcount?.visibleCategories.length > 1
                ) {
                  const currentUrl = this.router.url;
                  if (currentUrl === '/choose-brand') {
                    window.location.reload();
                  } else {
                    this.router.navigate(['/choose-brand']).then((sucess) => {
                      window.location.reload();
                    });
                  }
                } else if (
                  cutmrAcount?.visibleCategories &&
                  cutmrAcount?.visibleCategories.length == 1
                ) {
                  this.setProductLine(cutmrAcount?.visibleCategories[0]);
                  // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  //   this.router.navigate([ProductLineHomePageURL[cutmrAcount?.visibleCategories[0]]]);
                  // });
                  if (
                    this.router.url ==
                    ProductLineHomePageURL[cutmrAcount?.visibleCategories[0]]
                  ) {
                    window.location.reload();
                  } else {
                    this.router.navigate([
                      ProductLineHomePageURL[cutmrAcount?.visibleCategories[0]],
                    ]);
                  }
                } else {
                  this.setProductLine('');
                  this.refreshCurrentRoute();
                }
              });
          }
        }
      });
  }

  refreshCurrentRoute() {
    this.router.navigate(['/homepage']).then((success) => {
      window.location.reload();
    });
  }

  routeWagatePage(route) {
    this.router.navigate(['/', route]).then((sucess) => {
      window.location.reload();
    });
  }
  public addCustomerAcctoFav(payload: any) {
    let url = this.occEndpointService.buildUrl(
      'users/current/addFavouriteSoldto'
    );
    const apiParams = { soldToUid: payload?.payload?.uid };
    return this.apiService.putData_options(url, {}, { params: apiParams });
  }
  public removeCustomerAcctoFav(payload: any) {
    let url = this.occEndpointService.buildUrl(
      'users/current/removeFavoriteSoldTo'
    );

    const apiParams = { soldToUid: payload?.payload?.uid };
    return this.apiService.deleteData(url, { params: apiParams });
  }
  public getMyProfile(): Observable<any> {
    return this.apiService.getData(
      this.occEndpointService.buildUrl('users/current/myprofile')
    );
  }

  openSlider() {
    this.sliderSource.next(true);
  }

  getGuestSalesIdFromProductLine(productLine) {
    if (productLine === AllProductLine.waygate) {
      return wayagteGuestSalesId;
    } else if (productLine === AllProductLine.bently) {
      return bentlyGuestSalesId;
    } else if (productLine === AllProductLine.panametrics) {
      return panametricsGuestSalesId;
    } else if (productLine === AllProductLine.druck) {
      return druckGuestSalesId;
    } else if (productLine === AllProductLine.reuterStokes) {
      return reuterStokesGuestSalesId;
    }
  }

  openNotifiactionSlider() {
    this.notificationSliderSource.next(true);
  }

  updateNotification(newValue) {
    this.notificationData$.next(newValue);
  }

  get notificationData() {
    return this.notificationData$.asObservable();
  }

  verifyAndFetchNotification(b2bUnit) {
    const orderData = JSON.parse(
      sessionStorage.getItem('notifications_' + b2bUnit)
    );
    if (!orderData) {
      this.fetchNotificationFromBackend(b2bUnit);
    } else {
      this.updateNotification(orderData);
    }
  }

  fetchNotificationFromBackend(b2bUnit) {
    this.orderTrackingService.getNotifications().subscribe((response: any) => {
      if (response) {
        this.updateNotification(response);
        sessionStorage.setItem(
          `notifications_${b2bUnit}`,
          JSON.stringify(response)
        );
      }
    });
  }
}
