import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule, provideConfig } from '@spartacus/core';

import { ConfiguratorProductTitleModule } from './product-configurator-title/configurator-product-title.module';
import { ConfiguratorTabBarModule } from './configurator-tab-bar';
import { ConfiguratorGroupMenuModule } from './group-menu';
import { ConfiguratorPreviousNextButtonsModule } from './prev-next-buttons';
import { ConfiguratorExitButtonModule } from './exit-button';
import { ConfiguratorAddToCartButtonModule } from './product-overview/add-to-cart-button';
import { ConfiguratorFormModule } from './form';
import { ConfiguratorGroupModule } from './group';
import { ConfiguratorAttributeCheckboxListModule } from './checkbox-list';
import { ConfiguratorAttributeRadioButtonModule } from './radio-button';




/* import { ConfiguratorFormModule } from './config-form'; */



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KeyboardFocusModule,
    IconModule,
    I18nModule,
    ConfiguratorTabBarModule,
    ConfiguratorProductTitleModule,
    ConfiguratorGroupMenuModule,
    ConfiguratorPreviousNextButtonsModule,
    ConfiguratorExitButtonModule,
    ConfiguratorAddToCartButtonModule,
    ConfiguratorFormModule,
    ConfiguratorGroupModule,
    ConfiguratorAttributeCheckboxListModule,
    ConfiguratorAttributeRadioButtonModule,
    
  ],
  providers: [],
})
export class ProductConfigurationModule {}
