<ng-container *ngIf="container$ | async as container">
  <button
    class="btn btn-tertiary"
    tabindex="0"
    (click)="openConfirmationModal()"
    [attr.title]="
      container.routerData.isOwnerCartEntry
        ? ('configurator.button.cancelConfiguration' | cxTranslate)
        : ('configurator.button.exit' | cxTranslate)
    "
  >
    <ng-container *ngIf="!container.routerData.isOwnerCartEntry">
      <ng-container *ngIf="isDesktop() | async">
        <div class="total-container">
          <p class="have-a-question-label">Have a question?</p>
          <i class="material-icons" >
          close
        </i>
        </div>
        
      </ng-container>
      <ng-container *ngIf="isMobile() | async">
        <div class="total-container">
          <p class="have-a-question-label">Have a question?</p>
          <i class="material-icons" >
          close
        </i>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="container.routerData.isOwnerCartEntry">
      <ng-container *ngIf="isMobile() | async">
        <div class="total-container">
          <p class="have-a-question-label">Have a question?</p>
          <i class="material-icons" >
          close
        </i>
        </div>
      </ng-container>
      <ng-container *ngIf="isDesktop() | async">
        <div class="total-container">
          <p class="have-a-question-label">Have a question?</p>
          <i class="material-icons" >
          close
        </i>
        </div>
      </ng-container>
    </ng-container>
  </button>
</ng-container>