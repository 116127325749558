import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfiguratorGroupMenuComponent } from '@spartacus/product-configurator/rulebased';

@Component({
  selector: 'cx-configurator-group-menu',
  templateUrl: './configurator-group-menu.component.html',
  styleUrls: ['./configurator-group-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomConfiguratorGroupMenuComponent extends ConfiguratorGroupMenuComponent {}
